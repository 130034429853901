/* ---------------------------------------------- */
/* ###############  Typography ############### */
/* ---------------------------------------------- */
.tx-black {
    font-weight: 900;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-poppins {
    font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
}

.tx-roboto {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.tx-hind {
    font-family: "Hind", "Helvetica Neue", Arial, sans-serif;
}

.tx-firasans {
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
}

.tx-source {
    font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
}

.tx-mont {
    font-family: "Montserrat", "Fira Sans", "Helvetica Neue", Arial, sans-serif;
}

.tx-lato {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.tx-barcode {
    font-family: "Libre Barcode 39 Extended Text", cursive;
}

.tx-white {
    color: #fff;
}

.tx-black {
    color: #000;
}

.tx-primary {
    color: #0866C6;
}

.tx-success {
    color: #23BF08;
}

.tx-warning {
    color: #F49917;
}

.tx-danger {
    color: #dc3545;
}

.tx-info {
    color: #17a2b8;
}

.tx-inverse {
    color: #343a40;
}

.tx-teal {
    color: #00b297;
}

.tx-dark {
    color: #343a40;
}

.tx-indigo {
    color: #6610f2;
}

.tx-purple {
    color: #6f42c1;
}

.tx-orange {
    color: #f27510;
}

.tx-pink {
    color: #e83e8c;
}

.tx-gray-100 {
    color: #f8f9fa;
}

.tx-gray-200 {
    color: #e9ecef;
}

.tx-gray-300 {
    color: #dee2e6;
}

.tx-gray-400 {
    color: #ced4da;
}

.tx-gray-500 {
    color: #adb5bd;
}

.tx-gray-600 {
    color: #868e96;
}

.tx-gray-700 {
    color: #495057;
}

.tx-gray-800 {
    color: #343a40;
}

.tx-gray-900 {
    color: #212529;
}

.tx-br-primary {
    color: #1D2939;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacign: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-center-force {
    text-align: center !important;
}

.tx-right-force {
    text-align: right !important;
}

.tx-left-force {
    text-align: left !important;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2.0;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.valign-top-force {
    vertical-align: top !important;
}

.valign-middle-force {
    vertical-align: middle !important;
}

.valign-bottom-force {
    vertical-align: baseline !important;
}

.tx-8 {
    font-size: 8px;
}

.tx-8-force {
    font-size: 8px !important;
}

.tx-9 {
    font-size: 9px;
}

.tx-9-force {
    font-size: 9px !important;
}

.tx-10 {
    font-size: 10px;
}

.tx-10-force {
    font-size: 10px !important;
}

.tx-11 {
    font-size: 11px;
}

.tx-11-force {
    font-size: 11px !important;
}

.tx-12 {
    font-size: 12px;
}

.tx-12-force {
    font-size: 12px !important;
}

.tx-13 {
    font-size: 13px;
}

.tx-13-force {
    font-size: 13px !important;
}

.tx-14 {
    font-size: 14px;
}

.tx-14-force {
    font-size: 14px !important;
}

.tx-15 {
    font-size: 15px;
}

.tx-15-force {
    font-size: 15px !important;
}

.tx-16 {
    font-size: 16px;
}

.tx-16-force {
    font-size: 16px !important;
}

.tx-18 {
    font-size: 18px;
}

.tx-18-force {
    font-size: 18px !important;
}

.tx-20 {
    font-size: 20px;
}

.tx-20-force {
    font-size: 20px !important;
}

.tx-22 {
    font-size: 22px;
}

.tx-22-force {
    font-size: 22px !important;
}

.tx-24 {
    font-size: 24px;
}

.tx-24-force {
    font-size: 24px !important;
}

.tx-26 {
    font-size: 26px;
}

.tx-26-force {
    font-size: 26px !important;
}

.tx-28 {
    font-size: 28px;
}

.tx-28-force {
    font-size: 28px !important;
}

.tx-30 {
    font-size: 30px;
}

.tx-30-force {
    font-size: 30px !important;
}

.tx-32 {
    font-size: 32px;
}

.tx-32-force {
    font-size: 32px !important;
}

.tx-34 {
    font-size: 34px;
}

.tx-34-force {
    font-size: 34px !important;
}

.tx-36 {
    font-size: 36px;
}

.tx-36-force {
    font-size: 36px !important;
}

.tx-38 {
    font-size: 38px;
}

.tx-38-force {
    font-size: 38px !important;
}

.tx-40 {
    font-size: 40px;
}

.tx-40-force {
    font-size: 40px !important;
}

.tx-42 {
    font-size: 42px;
}

.tx-42-force {
    font-size: 42px !important;
}

.tx-44 {
    font-size: 44px;
}

.tx-44-force {
    font-size: 44px !important;
}

.tx-46 {
    font-size: 46px;
}

.tx-46-force {
    font-size: 46px !important;
}

.tx-48 {
    font-size: 48px;
}

.tx-48-force {
    font-size: 48px !important;
}

.tx-50 {
    font-size: 50px;
}

.tx-50-force {
    font-size: 50px !important;
}

.tx-52 {
    font-size: 52px;
}

.tx-52-force {
    font-size: 52px !important;
}

.tx-54 {
    font-size: 54px;
}

.tx-54-force {
    font-size: 54px !important;
}

.tx-56 {
    font-size: 56px;
}

.tx-56-force {
    font-size: 56px !important;
}

.tx-58 {
    font-size: 58px;
}

.tx-58-force {
    font-size: 58px !important;
}

.tx-60 {
    font-size: 60px;
}

.tx-60-force {
    font-size: 60px !important;
}

.tx-62 {
    font-size: 62px;
}

.tx-62-force {
    font-size: 62px !important;
}

.tx-64 {
    font-size: 64px;
}

.tx-64-force {
    font-size: 64px !important;
}

.tx-66 {
    font-size: 66px;
}

.tx-66-force {
    font-size: 66px !important;
}

.tx-68 {
    font-size: 68px;
}

.tx-68-force {
    font-size: 68px !important;
}

.tx-70 {
    font-size: 70px;
}

.tx-70-force {
    font-size: 70px !important;
}

.tx-72 {
    font-size: 72px;
}

.tx-72-force {
    font-size: 72px !important;
}

.tx-74 {
    font-size: 74px;
}

.tx-74-force {
    font-size: 74px !important;
}

.tx-76 {
    font-size: 76px;
}

.tx-76-force {
    font-size: 76px !important;
}

.tx-78 {
    font-size: 78px;
}

.tx-78-force {
    font-size: 78px !important;
}

.tx-80 {
    font-size: 80px;
}

.tx-80-force {
    font-size: 80px !important;
}

.tx-82 {
    font-size: 82px;
}

.tx-82-force {
    font-size: 82px !important;
}

.tx-84 {
    font-size: 84px;
}

.tx-84-force {
    font-size: 84px !important;
}

.tx-86 {
    font-size: 86px;
}

.tx-86-force {
    font-size: 86px !important;
}

.tx-88 {
    font-size: 88px;
}

.tx-88-force {
    font-size: 88px !important;
}

.tx-90 {
    font-size: 90px;
}

.tx-90-force {
    font-size: 90px !important;
}

.tx-92 {
    font-size: 92px;
}

.tx-92-force {
    font-size: 92px !important;
}

.tx-94 {
    font-size: 94px;
}

.tx-94-force {
    font-size: 94px !important;
}

.tx-96 {
    font-size: 96px;
}

.tx-96-force {
    font-size: 96px !important;
}

.tx-98 {
    font-size: 98px;
}

.tx-98-force {
    font-size: 98px !important;
}

.tx-100 {
    font-size: 100px;
}

.tx-100-force {
    font-size: 100px !important;
}

.tx-102 {
    font-size: 102px;
}

.tx-102-force {
    font-size: 102px !important;
}

.tx-104 {
    font-size: 104px;
}

.tx-104-force {
    font-size: 104px !important;
}

.tx-106 {
    font-size: 106px;
}

.tx-106-force {
    font-size: 106px !important;
}

.tx-108 {
    font-size: 108px;
}

.tx-108-force {
    font-size: 108px !important;
}

.tx-110 {
    font-size: 110px;
}

.tx-110-force {
    font-size: 110px !important;
}

.tx-112 {
    font-size: 112px;
}

.tx-112-force {
    font-size: 112px !important;
}

.tx-114 {
    font-size: 114px;
}

.tx-114-force {
    font-size: 114px !important;
}

.tx-116 {
    font-size: 116px;
}

.tx-116-force {
    font-size: 116px !important;
}

.tx-118 {
    font-size: 118px;
}

.tx-118-force {
    font-size: 118px !important;
}

.tx-120 {
    font-size: 120px;
}

.tx-120-force {
    font-size: 120px !important;
}

.tx-122 {
    font-size: 122px;
}

.tx-122-force {
    font-size: 122px !important;
}

.tx-124 {
    font-size: 124px;
}

.tx-124-force {
    font-size: 124px !important;
}

.tx-126 {
    font-size: 126px;
}

.tx-126-force {
    font-size: 126px !important;
}

.tx-128 {
    font-size: 128px;
}

.tx-128-force {
    font-size: 128px !important;
}

.tx-130 {
    font-size: 130px;
}

.tx-130-force {
    font-size: 130px !important;
}

.tx-132 {
    font-size: 132px;
}

.tx-132-force {
    font-size: 132px !important;
}

.tx-134 {
    font-size: 134px;
}

.tx-134-force {
    font-size: 134px !important;
}

.tx-136 {
    font-size: 136px;
}

.tx-136-force {
    font-size: 136px !important;
}

.tx-138 {
    font-size: 138px;
}

.tx-138-force {
    font-size: 138px !important;
}

.tx-140 {
    font-size: 140px;
}

.tx-140-force {
    font-size: 140px !important;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-8-force {
        font-size: 8px !important;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-9-force {
        font-size: 9px !important;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-10-force {
        font-size: 10px !important;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-11-force {
        font-size: 11px !important;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-12-force {
        font-size: 12px !important;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-13-force {
        font-size: 13px !important;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-14-force {
        font-size: 14px !important;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-15-force {
        font-size: 15px !important;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-16-force {
        font-size: 16px !important;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-18-force {
        font-size: 18px !important;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-20-force {
        font-size: 20px !important;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-22-force {
        font-size: 22px !important;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-24-force {
        font-size: 24px !important;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-26-force {
        font-size: 26px !important;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-28-force {
        font-size: 28px !important;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-30-force {
        font-size: 30px !important;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-32-force {
        font-size: 32px !important;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-34-force {
        font-size: 34px !important;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-36-force {
        font-size: 36px !important;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-38-force {
        font-size: 38px !important;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-40-force {
        font-size: 40px !important;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-42-force {
        font-size: 42px !important;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-44-force {
        font-size: 44px !important;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-46-force {
        font-size: 46px !important;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-48-force {
        font-size: 48px !important;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-50-force {
        font-size: 50px !important;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-52-force {
        font-size: 52px !important;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-54-force {
        font-size: 54px !important;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-56-force {
        font-size: 56px !important;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-58-force {
        font-size: 58px !important;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-60-force {
        font-size: 60px !important;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-62-force {
        font-size: 62px !important;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-64-force {
        font-size: 64px !important;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-66-force {
        font-size: 66px !important;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-68-force {
        font-size: 68px !important;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-70-force {
        font-size: 70px !important;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-72-force {
        font-size: 72px !important;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-74-force {
        font-size: 74px !important;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-76-force {
        font-size: 76px !important;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-78-force {
        font-size: 78px !important;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-80-force {
        font-size: 80px !important;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-82-force {
        font-size: 82px !important;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-84-force {
        font-size: 84px !important;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-86-force {
        font-size: 86px !important;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-88-force {
        font-size: 88px !important;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-90-force {
        font-size: 90px !important;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-92-force {
        font-size: 92px !important;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-94-force {
        font-size: 94px !important;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-96-force {
        font-size: 96px !important;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-98-force {
        font-size: 98px !important;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-100-force {
        font-size: 100px !important;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-102-force {
        font-size: 102px !important;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-104-force {
        font-size: 104px !important;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-106-force {
        font-size: 106px !important;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-108-force {
        font-size: 108px !important;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-110-force {
        font-size: 110px !important;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-112-force {
        font-size: 112px !important;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-114-force {
        font-size: 114px !important;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-116-force {
        font-size: 116px !important;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-118-force {
        font-size: 118px !important;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-120-force {
        font-size: 120px !important;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-122-force {
        font-size: 122px !important;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-124-force {
        font-size: 124px !important;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-126-force {
        font-size: 126px !important;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-128-force {
        font-size: 128px !important;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-130-force {
        font-size: 130px !important;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-132-force {
        font-size: 132px !important;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-134-force {
        font-size: 134px !important;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-136-force {
        font-size: 136px !important;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-138-force {
        font-size: 138px !important;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-140-force {
        font-size: 140px !important;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2.0;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }

    .tx-xs-center-force {
        text-align: center !important;
    }

    .tx-xs-right-force {
        text-align: right !important;
    }

    .tx-xs-left-force {
        text-align: left !important;
    }
}
@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-8-force {
        font-size: 8px !important;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-9-force {
        font-size: 9px !important;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-10-force {
        font-size: 10px !important;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-11-force {
        font-size: 11px !important;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-12-force {
        font-size: 12px !important;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-13-force {
        font-size: 13px !important;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-14-force {
        font-size: 14px !important;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-15-force {
        font-size: 15px !important;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-16-force {
        font-size: 16px !important;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-18-force {
        font-size: 18px !important;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-20-force {
        font-size: 20px !important;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-22-force {
        font-size: 22px !important;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-24-force {
        font-size: 24px !important;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-26-force {
        font-size: 26px !important;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-28-force {
        font-size: 28px !important;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-30-force {
        font-size: 30px !important;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-32-force {
        font-size: 32px !important;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-34-force {
        font-size: 34px !important;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-36-force {
        font-size: 36px !important;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-38-force {
        font-size: 38px !important;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-40-force {
        font-size: 40px !important;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-42-force {
        font-size: 42px !important;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-44-force {
        font-size: 44px !important;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-46-force {
        font-size: 46px !important;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-48-force {
        font-size: 48px !important;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-50-force {
        font-size: 50px !important;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-52-force {
        font-size: 52px !important;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-54-force {
        font-size: 54px !important;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-56-force {
        font-size: 56px !important;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-58-force {
        font-size: 58px !important;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-60-force {
        font-size: 60px !important;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-62-force {
        font-size: 62px !important;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-64-force {
        font-size: 64px !important;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-66-force {
        font-size: 66px !important;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-68-force {
        font-size: 68px !important;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-70-force {
        font-size: 70px !important;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-72-force {
        font-size: 72px !important;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-74-force {
        font-size: 74px !important;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-76-force {
        font-size: 76px !important;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-78-force {
        font-size: 78px !important;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-80-force {
        font-size: 80px !important;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-82-force {
        font-size: 82px !important;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-84-force {
        font-size: 84px !important;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-86-force {
        font-size: 86px !important;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-88-force {
        font-size: 88px !important;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-90-force {
        font-size: 90px !important;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-92-force {
        font-size: 92px !important;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-94-force {
        font-size: 94px !important;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-96-force {
        font-size: 96px !important;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-98-force {
        font-size: 98px !important;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-100-force {
        font-size: 100px !important;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-102-force {
        font-size: 102px !important;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-104-force {
        font-size: 104px !important;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-106-force {
        font-size: 106px !important;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-108-force {
        font-size: 108px !important;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-110-force {
        font-size: 110px !important;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-112-force {
        font-size: 112px !important;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-114-force {
        font-size: 114px !important;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-116-force {
        font-size: 116px !important;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-118-force {
        font-size: 118px !important;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-120-force {
        font-size: 120px !important;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-122-force {
        font-size: 122px !important;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-124-force {
        font-size: 124px !important;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-126-force {
        font-size: 126px !important;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-128-force {
        font-size: 128px !important;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-130-force {
        font-size: 130px !important;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-132-force {
        font-size: 132px !important;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-134-force {
        font-size: 134px !important;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-136-force {
        font-size: 136px !important;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-138-force {
        font-size: 138px !important;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-140-force {
        font-size: 140px !important;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2.0;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }

    .tx-sm-center-force {
        text-align: center !important;
    }

    .tx-sm-right-force {
        text-align: right !important;
    }

    .tx-sm-left-force {
        text-align: left !important;
    }
}
@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-8-force {
        font-size: 8px !important;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-9-force {
        font-size: 9px !important;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-10-force {
        font-size: 10px !important;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-11-force {
        font-size: 11px !important;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-12-force {
        font-size: 12px !important;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-13-force {
        font-size: 13px !important;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-14-force {
        font-size: 14px !important;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-15-force {
        font-size: 15px !important;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-16-force {
        font-size: 16px !important;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-18-force {
        font-size: 18px !important;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-20-force {
        font-size: 20px !important;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-22-force {
        font-size: 22px !important;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-24-force {
        font-size: 24px !important;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-26-force {
        font-size: 26px !important;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-28-force {
        font-size: 28px !important;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-30-force {
        font-size: 30px !important;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-32-force {
        font-size: 32px !important;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-34-force {
        font-size: 34px !important;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-36-force {
        font-size: 36px !important;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-38-force {
        font-size: 38px !important;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-40-force {
        font-size: 40px !important;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-42-force {
        font-size: 42px !important;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-44-force {
        font-size: 44px !important;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-46-force {
        font-size: 46px !important;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-48-force {
        font-size: 48px !important;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-50-force {
        font-size: 50px !important;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-52-force {
        font-size: 52px !important;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-54-force {
        font-size: 54px !important;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-56-force {
        font-size: 56px !important;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-58-force {
        font-size: 58px !important;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-60-force {
        font-size: 60px !important;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-62-force {
        font-size: 62px !important;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-64-force {
        font-size: 64px !important;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-66-force {
        font-size: 66px !important;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-68-force {
        font-size: 68px !important;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-70-force {
        font-size: 70px !important;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-72-force {
        font-size: 72px !important;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-74-force {
        font-size: 74px !important;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-76-force {
        font-size: 76px !important;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-78-force {
        font-size: 78px !important;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-80-force {
        font-size: 80px !important;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-82-force {
        font-size: 82px !important;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-84-force {
        font-size: 84px !important;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-86-force {
        font-size: 86px !important;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-88-force {
        font-size: 88px !important;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-90-force {
        font-size: 90px !important;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-92-force {
        font-size: 92px !important;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-94-force {
        font-size: 94px !important;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-96-force {
        font-size: 96px !important;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-98-force {
        font-size: 98px !important;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-100-force {
        font-size: 100px !important;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-102-force {
        font-size: 102px !important;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-104-force {
        font-size: 104px !important;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-106-force {
        font-size: 106px !important;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-108-force {
        font-size: 108px !important;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-110-force {
        font-size: 110px !important;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-112-force {
        font-size: 112px !important;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-114-force {
        font-size: 114px !important;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-116-force {
        font-size: 116px !important;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-118-force {
        font-size: 118px !important;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-120-force {
        font-size: 120px !important;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-122-force {
        font-size: 122px !important;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-124-force {
        font-size: 124px !important;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-126-force {
        font-size: 126px !important;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-128-force {
        font-size: 128px !important;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-130-force {
        font-size: 130px !important;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-132-force {
        font-size: 132px !important;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-134-force {
        font-size: 134px !important;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-136-force {
        font-size: 136px !important;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-138-force {
        font-size: 138px !important;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-140-force {
        font-size: 140px !important;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2.0;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }

    .tx-md-center-force {
        text-align: center !important;
    }

    .tx-md-right-force {
        text-align: right !important;
    }

    .tx-md-left-force {
        text-align: left !important;
    }
}
@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-8-force {
        font-size: 8px !important;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-9-force {
        font-size: 9px !important;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-10-force {
        font-size: 10px !important;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-11-force {
        font-size: 11px !important;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-12-force {
        font-size: 12px !important;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-13-force {
        font-size: 13px !important;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-14-force {
        font-size: 14px !important;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-15-force {
        font-size: 15px !important;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-16-force {
        font-size: 16px !important;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-18-force {
        font-size: 18px !important;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-20-force {
        font-size: 20px !important;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-22-force {
        font-size: 22px !important;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-24-force {
        font-size: 24px !important;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-26-force {
        font-size: 26px !important;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-28-force {
        font-size: 28px !important;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-30-force {
        font-size: 30px !important;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-32-force {
        font-size: 32px !important;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-34-force {
        font-size: 34px !important;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-36-force {
        font-size: 36px !important;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-38-force {
        font-size: 38px !important;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-40-force {
        font-size: 40px !important;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-42-force {
        font-size: 42px !important;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-44-force {
        font-size: 44px !important;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-46-force {
        font-size: 46px !important;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-48-force {
        font-size: 48px !important;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-50-force {
        font-size: 50px !important;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-52-force {
        font-size: 52px !important;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-54-force {
        font-size: 54px !important;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-56-force {
        font-size: 56px !important;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-58-force {
        font-size: 58px !important;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-60-force {
        font-size: 60px !important;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-62-force {
        font-size: 62px !important;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-64-force {
        font-size: 64px !important;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-66-force {
        font-size: 66px !important;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-68-force {
        font-size: 68px !important;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-70-force {
        font-size: 70px !important;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-72-force {
        font-size: 72px !important;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-74-force {
        font-size: 74px !important;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-76-force {
        font-size: 76px !important;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-78-force {
        font-size: 78px !important;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-80-force {
        font-size: 80px !important;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-82-force {
        font-size: 82px !important;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-84-force {
        font-size: 84px !important;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-86-force {
        font-size: 86px !important;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-88-force {
        font-size: 88px !important;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-90-force {
        font-size: 90px !important;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-92-force {
        font-size: 92px !important;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-94-force {
        font-size: 94px !important;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-96-force {
        font-size: 96px !important;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-98-force {
        font-size: 98px !important;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-100-force {
        font-size: 100px !important;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-102-force {
        font-size: 102px !important;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-104-force {
        font-size: 104px !important;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-106-force {
        font-size: 106px !important;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-108-force {
        font-size: 108px !important;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-110-force {
        font-size: 110px !important;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-112-force {
        font-size: 112px !important;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-114-force {
        font-size: 114px !important;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-116-force {
        font-size: 116px !important;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-118-force {
        font-size: 118px !important;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-120-force {
        font-size: 120px !important;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-122-force {
        font-size: 122px !important;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-124-force {
        font-size: 124px !important;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-126-force {
        font-size: 126px !important;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-128-force {
        font-size: 128px !important;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-130-force {
        font-size: 130px !important;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-132-force {
        font-size: 132px !important;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-134-force {
        font-size: 134px !important;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-136-force {
        font-size: 136px !important;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-138-force {
        font-size: 138px !important;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-140-force {
        font-size: 140px !important;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2.0;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }

    .tx-lg-center-force {
        text-align: center !important;
    }

    .tx-lg-right-force {
        text-align: right !important;
    }

    .tx-lg-left-force {
        text-align: left !important;
    }
}
@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-8-force {
        font-size: 8px !important;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-9-force {
        font-size: 9px !important;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-10-force {
        font-size: 10px !important;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-11-force {
        font-size: 11px !important;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-12-force {
        font-size: 12px !important;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-13-force {
        font-size: 13px !important;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-14-force {
        font-size: 14px !important;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-15-force {
        font-size: 15px !important;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-16-force {
        font-size: 16px !important;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-18-force {
        font-size: 18px !important;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-20-force {
        font-size: 20px !important;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-22-force {
        font-size: 22px !important;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-24-force {
        font-size: 24px !important;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-26-force {
        font-size: 26px !important;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-28-force {
        font-size: 28px !important;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-30-force {
        font-size: 30px !important;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-32-force {
        font-size: 32px !important;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-34-force {
        font-size: 34px !important;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-36-force {
        font-size: 36px !important;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-38-force {
        font-size: 38px !important;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-40-force {
        font-size: 40px !important;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-42-force {
        font-size: 42px !important;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-44-force {
        font-size: 44px !important;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-46-force {
        font-size: 46px !important;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-48-force {
        font-size: 48px !important;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-50-force {
        font-size: 50px !important;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-52-force {
        font-size: 52px !important;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-54-force {
        font-size: 54px !important;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-56-force {
        font-size: 56px !important;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-58-force {
        font-size: 58px !important;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-60-force {
        font-size: 60px !important;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-62-force {
        font-size: 62px !important;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-64-force {
        font-size: 64px !important;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-66-force {
        font-size: 66px !important;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-68-force {
        font-size: 68px !important;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-70-force {
        font-size: 70px !important;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-72-force {
        font-size: 72px !important;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-74-force {
        font-size: 74px !important;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-76-force {
        font-size: 76px !important;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-78-force {
        font-size: 78px !important;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-80-force {
        font-size: 80px !important;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-82-force {
        font-size: 82px !important;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-84-force {
        font-size: 84px !important;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-86-force {
        font-size: 86px !important;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-88-force {
        font-size: 88px !important;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-90-force {
        font-size: 90px !important;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-92-force {
        font-size: 92px !important;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-94-force {
        font-size: 94px !important;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-96-force {
        font-size: 96px !important;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-98-force {
        font-size: 98px !important;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-100-force {
        font-size: 100px !important;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-102-force {
        font-size: 102px !important;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-104-force {
        font-size: 104px !important;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-106-force {
        font-size: 106px !important;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-108-force {
        font-size: 108px !important;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-110-force {
        font-size: 110px !important;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-112-force {
        font-size: 112px !important;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-114-force {
        font-size: 114px !important;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-116-force {
        font-size: 116px !important;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-118-force {
        font-size: 118px !important;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-120-force {
        font-size: 120px !important;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-122-force {
        font-size: 122px !important;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-124-force {
        font-size: 124px !important;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-126-force {
        font-size: 126px !important;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-128-force {
        font-size: 128px !important;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-130-force {
        font-size: 130px !important;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-132-force {
        font-size: 132px !important;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-134-force {
        font-size: 134px !important;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-136-force {
        font-size: 136px !important;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-138-force {
        font-size: 138px !important;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-140-force {
        font-size: 140px !important;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2.0;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }

    .tx-xl-center-force {
        text-align: center !important;
    }

    .tx-xl-right-force {
        text-align: right !important;
    }

    .tx-xl-left-force {
        text-align: left !important;
    }
}
/* ------------------------------------------ */
/* ############### 6.10 Width ############### */
/* ------------------------------------------ */
.wd-5 {
    width: 5px;
}

.wd-5p {
    width: 5%;
}

.mx-wd-5p {
    max-width: 5%;
}

.mn-wd-5p {
    min-width: 5%;
}

.wd-5-force {
    width: 5px !important;
}

.wd-5p-force {
    width: 5% !important;
}

.mx-wd-5p-force {
    max-width: 5% !important;
}

.mn-wd-5p-force {
    min-width: 5% !important;
}

.wd-10 {
    width: 10px;
}

.wd-10p {
    width: 10%;
}

.mx-wd-10p {
    max-width: 10%;
}

.mn-wd-10p {
    min-width: 10%;
}

.wd-10-force {
    width: 10px !important;
}

.wd-10p-force {
    width: 10% !important;
}

.mx-wd-10p-force {
    max-width: 10% !important;
}

.mn-wd-10p-force {
    min-width: 10% !important;
}

.wd-15 {
    width: 15px;
}

.wd-15p {
    width: 15%;
}

.mx-wd-15p {
    max-width: 15%;
}

.mn-wd-15p {
    min-width: 15%;
}

.wd-15-force {
    width: 15px !important;
}

.wd-15p-force {
    width: 15% !important;
}

.mx-wd-15p-force {
    max-width: 15% !important;
}

.mn-wd-15p-force {
    min-width: 15% !important;
}

.wd-20 {
    width: 20px;
}

.wd-20p {
    width: 20%;
}

.mx-wd-20p {
    max-width: 20%;
}

.mn-wd-20p {
    min-width: 20%;
}

.wd-20-force {
    width: 20px !important;
}

.wd-20p-force {
    width: 20% !important;
}

.mx-wd-20p-force {
    max-width: 20% !important;
}

.mn-wd-20p-force {
    min-width: 20% !important;
}

.wd-25 {
    width: 25px;
}

.wd-25p {
    width: 25%;
}

.mx-wd-25p {
    max-width: 25%;
}

.mn-wd-25p {
    min-width: 25%;
}

.wd-25-force {
    width: 25px !important;
}

.wd-25p-force {
    width: 25% !important;
}

.mx-wd-25p-force {
    max-width: 25% !important;
}

.mn-wd-25p-force {
    min-width: 25% !important;
}

.wd-30 {
    width: 30px;
}

.wd-30p {
    width: 30%;
}

.mx-wd-30p {
    max-width: 30%;
}

.mn-wd-30p {
    min-width: 30%;
}

.wd-30-force {
    width: 30px !important;
}

.wd-30p-force {
    width: 30% !important;
}

.mx-wd-30p-force {
    max-width: 30% !important;
}

.mn-wd-30p-force {
    min-width: 30% !important;
}

.wd-35 {
    width: 35px;
}

.wd-35p {
    width: 35%;
}

.mx-wd-35p {
    max-width: 35%;
}

.mn-wd-35p {
    min-width: 35%;
}

.wd-35-force {
    width: 35px !important;
}

.wd-35p-force {
    width: 35% !important;
}

.mx-wd-35p-force {
    max-width: 35% !important;
}

.mn-wd-35p-force {
    min-width: 35% !important;
}

.wd-40 {
    width: 40px;
}

.wd-40p {
    width: 40%;
}

.mx-wd-40p {
    max-width: 40%;
}

.mn-wd-40p {
    min-width: 40%;
}

.wd-40-force {
    width: 40px !important;
}

.wd-40p-force {
    width: 40% !important;
}

.mx-wd-40p-force {
    max-width: 40% !important;
}

.mn-wd-40p-force {
    min-width: 40% !important;
}

.wd-45 {
    width: 45px;
}

.wd-45p {
    width: 45%;
}

.mx-wd-45p {
    max-width: 45%;
}

.mn-wd-45p {
    min-width: 45%;
}

.wd-45-force {
    width: 45px !important;
}

.wd-45p-force {
    width: 45% !important;
}

.mx-wd-45p-force {
    max-width: 45% !important;
}

.mn-wd-45p-force {
    min-width: 45% !important;
}

.wd-50 {
    width: 50px;
}

.wd-50p {
    width: 50%;
}

.mx-wd-50p {
    max-width: 50%;
}

.mn-wd-50p {
    min-width: 50%;
}

.wd-50-force {
    width: 50px !important;
}

.wd-50p-force {
    width: 50% !important;
}

.mx-wd-50p-force {
    max-width: 50% !important;
}

.mn-wd-50p-force {
    min-width: 50% !important;
}

.wd-55 {
    width: 55px;
}

.wd-55p {
    width: 55%;
}

.mx-wd-55p {
    max-width: 55%;
}

.mn-wd-55p {
    min-width: 55%;
}

.wd-55-force {
    width: 55px !important;
}

.wd-55p-force {
    width: 55% !important;
}

.mx-wd-55p-force {
    max-width: 55% !important;
}

.mn-wd-55p-force {
    min-width: 55% !important;
}

.wd-60 {
    width: 60px;
}

.wd-60p {
    width: 60%;
}

.mx-wd-60p {
    max-width: 60%;
}

.mn-wd-60p {
    min-width: 60%;
}

.wd-60-force {
    width: 60px !important;
}

.wd-60p-force {
    width: 60% !important;
}

.mx-wd-60p-force {
    max-width: 60% !important;
}

.mn-wd-60p-force {
    min-width: 60% !important;
}

.wd-65 {
    width: 65px;
}

.wd-65p {
    width: 65%;
}

.mx-wd-65p {
    max-width: 65%;
}

.mn-wd-65p {
    min-width: 65%;
}

.wd-65-force {
    width: 65px !important;
}

.wd-65p-force {
    width: 65% !important;
}

.mx-wd-65p-force {
    max-width: 65% !important;
}

.mn-wd-65p-force {
    min-width: 65% !important;
}

.wd-70 {
    width: 70px;
}

.wd-70p {
    width: 70%;
}

.mx-wd-70p {
    max-width: 70%;
}

.mn-wd-70p {
    min-width: 70%;
}

.wd-70-force {
    width: 70px !important;
}

.wd-70p-force {
    width: 70% !important;
}

.mx-wd-70p-force {
    max-width: 70% !important;
}

.mn-wd-70p-force {
    min-width: 70% !important;
}

.wd-75 {
    width: 75px;
}

.wd-75p {
    width: 75%;
}

.mx-wd-75p {
    max-width: 75%;
}

.mn-wd-75p {
    min-width: 75%;
}

.wd-75-force {
    width: 75px !important;
}

.wd-75p-force {
    width: 75% !important;
}

.mx-wd-75p-force {
    max-width: 75% !important;
}

.mn-wd-75p-force {
    min-width: 75% !important;
}

.wd-80 {
    width: 80px;
}

.wd-80p {
    width: 80%;
}

.mx-wd-80p {
    max-width: 80%;
}

.mn-wd-80p {
    min-width: 80%;
}

.wd-80-force {
    width: 80px !important;
}

.wd-80p-force {
    width: 80% !important;
}

.mx-wd-80p-force {
    max-width: 80% !important;
}

.mn-wd-80p-force {
    min-width: 80% !important;
}

.wd-85 {
    width: 85px;
}

.wd-85p {
    width: 85%;
}

.mx-wd-85p {
    max-width: 85%;
}

.mn-wd-85p {
    min-width: 85%;
}

.wd-85-force {
    width: 85px !important;
}

.wd-85p-force {
    width: 85% !important;
}

.mx-wd-85p-force {
    max-width: 85% !important;
}

.mn-wd-85p-force {
    min-width: 85% !important;
}

.wd-90 {
    width: 90px;
}

.wd-90p {
    width: 90%;
}

.mx-wd-90p {
    max-width: 90%;
}

.mn-wd-90p {
    min-width: 90%;
}

.wd-90-force {
    width: 90px !important;
}

.wd-90p-force {
    width: 90% !important;
}

.mx-wd-90p-force {
    max-width: 90% !important;
}

.mn-wd-90p-force {
    min-width: 90% !important;
}

.wd-95 {
    width: 95px;
}

.wd-95p {
    width: 95%;
}

.mx-wd-95p {
    max-width: 95%;
}

.mn-wd-95p {
    min-width: 95%;
}

.wd-95-force {
    width: 95px !important;
}

.wd-95p-force {
    width: 95% !important;
}

.mx-wd-95p-force {
    max-width: 95% !important;
}

.mn-wd-95p-force {
    min-width: 95% !important;
}

.wd-100 {
    width: 100px;
}

.wd-100p {
    width: 100%;
}

.mx-wd-100p {
    max-width: 100%;
}

.mn-wd-100p {
    min-width: 100%;
}

.wd-100-force {
    width: 100px !important;
}

.wd-100p-force {
    width: 100% !important;
}

.mx-wd-100p-force {
    max-width: 100% !important;
}

.mn-wd-100p-force {
    min-width: 100% !important;
}

.wd-150 {
    width: 150px;
}

.wd-150-force {
    width: 150px !important;
}

.wd-200 {
    width: 200px;
}

.wd-200-force {
    width: 200px !important;
}

.wd-250 {
    width: 250px;
}

.wd-250-force {
    width: 250px !important;
}

.wd-300 {
    width: 300px;
}

.wd-300-force {
    width: 300px !important;
}

.wd-350 {
    width: 350px;
}

.wd-350-force {
    width: 350px !important;
}

.wd-400 {
    width: 400px;
}

.wd-400-force {
    width: 400px !important;
}

.wd-450 {
    width: 450px;
}

.wd-450-force {
    width: 450px !important;
}

.wd-500 {
    width: 500px;
}

.wd-500-force {
    width: 500px !important;
}

.wd-550 {
    width: 550px;
}

.wd-550-force {
    width: 550px !important;
}

.wd-600 {
    width: 600px;
}

.wd-600-force {
    width: 600px !important;
}

.wd-650 {
    width: 650px;
}

.wd-650-force {
    width: 650px !important;
}

.wd-700 {
    width: 700px;
}

.wd-700-force {
    width: 700px !important;
}

.wd-750 {
    width: 750px;
}

.wd-750-force {
    width: 750px !important;
}

.wd-800 {
    width: 800px;
}

.wd-800-force {
    width: 800px !important;
}

.wd-850 {
    width: 850px;
}

.wd-850-force {
    width: 850px !important;
}

.wd-900 {
    width: 900px;
}

.wd-900-force {
    width: 900px !important;
}

.wd-950 {
    width: 950px;
}

.wd-950-force {
    width: 950px !important;
}

.wd-1000 {
    width: 1000px;
}

.wd-1000-force {
    width: 1000px !important;
}

@media (min-width: 480px) {
    .wd-xs-5 {
        width: 5px;
    }

    .wd-xs-5p {
        width: 5%;
    }

    .mx-wd-xs-5p {
        max-width: 5%;
    }

    .mn-wd-xs-5p {
        min-width: 5%;
    }

    .wd-xs-5-force {
        width: 5px !important;
    }

    .wd-xs-5p-force {
        width: 5% !important;
    }

    .mx-wd-xs-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-xs-5p-force {
        min-width: 5% !important;
    }

    .wd-xs-10 {
        width: 10px;
    }

    .wd-xs-10p {
        width: 10%;
    }

    .mx-wd-xs-10p {
        max-width: 10%;
    }

    .mn-wd-xs-10p {
        min-width: 10%;
    }

    .wd-xs-10-force {
        width: 10px !important;
    }

    .wd-xs-10p-force {
        width: 10% !important;
    }

    .mx-wd-xs-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-xs-10p-force {
        min-width: 10% !important;
    }

    .wd-xs-15 {
        width: 15px;
    }

    .wd-xs-15p {
        width: 15%;
    }

    .mx-wd-xs-15p {
        max-width: 15%;
    }

    .mn-wd-xs-15p {
        min-width: 15%;
    }

    .wd-xs-15-force {
        width: 15px !important;
    }

    .wd-xs-15p-force {
        width: 15% !important;
    }

    .mx-wd-xs-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-xs-15p-force {
        min-width: 15% !important;
    }

    .wd-xs-20 {
        width: 20px;
    }

    .wd-xs-20p {
        width: 20%;
    }

    .mx-wd-xs-20p {
        max-width: 20%;
    }

    .mn-wd-xs-20p {
        min-width: 20%;
    }

    .wd-xs-20-force {
        width: 20px !important;
    }

    .wd-xs-20p-force {
        width: 20% !important;
    }

    .mx-wd-xs-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-xs-20p-force {
        min-width: 20% !important;
    }

    .wd-xs-25 {
        width: 25px;
    }

    .wd-xs-25p {
        width: 25%;
    }

    .mx-wd-xs-25p {
        max-width: 25%;
    }

    .mn-wd-xs-25p {
        min-width: 25%;
    }

    .wd-xs-25-force {
        width: 25px !important;
    }

    .wd-xs-25p-force {
        width: 25% !important;
    }

    .mx-wd-xs-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-xs-25p-force {
        min-width: 25% !important;
    }

    .wd-xs-30 {
        width: 30px;
    }

    .wd-xs-30p {
        width: 30%;
    }

    .mx-wd-xs-30p {
        max-width: 30%;
    }

    .mn-wd-xs-30p {
        min-width: 30%;
    }

    .wd-xs-30-force {
        width: 30px !important;
    }

    .wd-xs-30p-force {
        width: 30% !important;
    }

    .mx-wd-xs-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-xs-30p-force {
        min-width: 30% !important;
    }

    .wd-xs-35 {
        width: 35px;
    }

    .wd-xs-35p {
        width: 35%;
    }

    .mx-wd-xs-35p {
        max-width: 35%;
    }

    .mn-wd-xs-35p {
        min-width: 35%;
    }

    .wd-xs-35-force {
        width: 35px !important;
    }

    .wd-xs-35p-force {
        width: 35% !important;
    }

    .mx-wd-xs-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-xs-35p-force {
        min-width: 35% !important;
    }

    .wd-xs-40 {
        width: 40px;
    }

    .wd-xs-40p {
        width: 40%;
    }

    .mx-wd-xs-40p {
        max-width: 40%;
    }

    .mn-wd-xs-40p {
        min-width: 40%;
    }

    .wd-xs-40-force {
        width: 40px !important;
    }

    .wd-xs-40p-force {
        width: 40% !important;
    }

    .mx-wd-xs-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-xs-40p-force {
        min-width: 40% !important;
    }

    .wd-xs-45 {
        width: 45px;
    }

    .wd-xs-45p {
        width: 45%;
    }

    .mx-wd-xs-45p {
        max-width: 45%;
    }

    .mn-wd-xs-45p {
        min-width: 45%;
    }

    .wd-xs-45-force {
        width: 45px !important;
    }

    .wd-xs-45p-force {
        width: 45% !important;
    }

    .mx-wd-xs-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-xs-45p-force {
        min-width: 45% !important;
    }

    .wd-xs-50 {
        width: 50px;
    }

    .wd-xs-50p {
        width: 50%;
    }

    .mx-wd-xs-50p {
        max-width: 50%;
    }

    .mn-wd-xs-50p {
        min-width: 50%;
    }

    .wd-xs-50-force {
        width: 50px !important;
    }

    .wd-xs-50p-force {
        width: 50% !important;
    }

    .mx-wd-xs-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-xs-50p-force {
        min-width: 50% !important;
    }

    .wd-xs-55 {
        width: 55px;
    }

    .wd-xs-55p {
        width: 55%;
    }

    .mx-wd-xs-55p {
        max-width: 55%;
    }

    .mn-wd-xs-55p {
        min-width: 55%;
    }

    .wd-xs-55-force {
        width: 55px !important;
    }

    .wd-xs-55p-force {
        width: 55% !important;
    }

    .mx-wd-xs-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-xs-55p-force {
        min-width: 55% !important;
    }

    .wd-xs-60 {
        width: 60px;
    }

    .wd-xs-60p {
        width: 60%;
    }

    .mx-wd-xs-60p {
        max-width: 60%;
    }

    .mn-wd-xs-60p {
        min-width: 60%;
    }

    .wd-xs-60-force {
        width: 60px !important;
    }

    .wd-xs-60p-force {
        width: 60% !important;
    }

    .mx-wd-xs-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-xs-60p-force {
        min-width: 60% !important;
    }

    .wd-xs-65 {
        width: 65px;
    }

    .wd-xs-65p {
        width: 65%;
    }

    .mx-wd-xs-65p {
        max-width: 65%;
    }

    .mn-wd-xs-65p {
        min-width: 65%;
    }

    .wd-xs-65-force {
        width: 65px !important;
    }

    .wd-xs-65p-force {
        width: 65% !important;
    }

    .mx-wd-xs-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-xs-65p-force {
        min-width: 65% !important;
    }

    .wd-xs-70 {
        width: 70px;
    }

    .wd-xs-70p {
        width: 70%;
    }

    .mx-wd-xs-70p {
        max-width: 70%;
    }

    .mn-wd-xs-70p {
        min-width: 70%;
    }

    .wd-xs-70-force {
        width: 70px !important;
    }

    .wd-xs-70p-force {
        width: 70% !important;
    }

    .mx-wd-xs-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-xs-70p-force {
        min-width: 70% !important;
    }

    .wd-xs-75 {
        width: 75px;
    }

    .wd-xs-75p {
        width: 75%;
    }

    .mx-wd-xs-75p {
        max-width: 75%;
    }

    .mn-wd-xs-75p {
        min-width: 75%;
    }

    .wd-xs-75-force {
        width: 75px !important;
    }

    .wd-xs-75p-force {
        width: 75% !important;
    }

    .mx-wd-xs-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-xs-75p-force {
        min-width: 75% !important;
    }

    .wd-xs-80 {
        width: 80px;
    }

    .wd-xs-80p {
        width: 80%;
    }

    .mx-wd-xs-80p {
        max-width: 80%;
    }

    .mn-wd-xs-80p {
        min-width: 80%;
    }

    .wd-xs-80-force {
        width: 80px !important;
    }

    .wd-xs-80p-force {
        width: 80% !important;
    }

    .mx-wd-xs-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-xs-80p-force {
        min-width: 80% !important;
    }

    .wd-xs-85 {
        width: 85px;
    }

    .wd-xs-85p {
        width: 85%;
    }

    .mx-wd-xs-85p {
        max-width: 85%;
    }

    .mn-wd-xs-85p {
        min-width: 85%;
    }

    .wd-xs-85-force {
        width: 85px !important;
    }

    .wd-xs-85p-force {
        width: 85% !important;
    }

    .mx-wd-xs-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-xs-85p-force {
        min-width: 85% !important;
    }

    .wd-xs-90 {
        width: 90px;
    }

    .wd-xs-90p {
        width: 90%;
    }

    .mx-wd-xs-90p {
        max-width: 90%;
    }

    .mn-wd-xs-90p {
        min-width: 90%;
    }

    .wd-xs-90-force {
        width: 90px !important;
    }

    .wd-xs-90p-force {
        width: 90% !important;
    }

    .mx-wd-xs-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-xs-90p-force {
        min-width: 90% !important;
    }

    .wd-xs-95 {
        width: 95px;
    }

    .wd-xs-95p {
        width: 95%;
    }

    .mx-wd-xs-95p {
        max-width: 95%;
    }

    .mn-wd-xs-95p {
        min-width: 95%;
    }

    .wd-xs-95-force {
        width: 95px !important;
    }

    .wd-xs-95p-force {
        width: 95% !important;
    }

    .mx-wd-xs-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-xs-95p-force {
        min-width: 95% !important;
    }

    .wd-xs-100 {
        width: 100px;
    }

    .wd-xs-100p {
        width: 100%;
    }

    .mx-wd-xs-100p {
        max-width: 100%;
    }

    .mn-wd-xs-100p {
        min-width: 100%;
    }

    .wd-xs-100-force {
        width: 100px !important;
    }

    .wd-xs-100p-force {
        width: 100% !important;
    }

    .mx-wd-xs-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-xs-100p-force {
        min-width: 100% !important;
    }

    .wd-xs-150 {
        width: 150px;
    }

    .wd-xs-150p {
        width: 150%;
    }

    .mx-wd-xs-150p {
        max-width: 150%;
    }

    .mn-wd-xs-150p {
        min-width: 150%;
    }

    .wd-xs-150-force {
        width: 150px !important;
    }

    .wd-xs-150p-force {
        width: 150% !important;
    }

    .mx-wd-xs-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-xs-150p-force {
        min-width: 150% !important;
    }

    .wd-xs-200 {
        width: 200px;
    }

    .wd-xs-200p {
        width: 200%;
    }

    .mx-wd-xs-200p {
        max-width: 200%;
    }

    .mn-wd-xs-200p {
        min-width: 200%;
    }

    .wd-xs-200-force {
        width: 200px !important;
    }

    .wd-xs-200p-force {
        width: 200% !important;
    }

    .mx-wd-xs-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-xs-200p-force {
        min-width: 200% !important;
    }

    .wd-xs-250 {
        width: 250px;
    }

    .wd-xs-250p {
        width: 250%;
    }

    .mx-wd-xs-250p {
        max-width: 250%;
    }

    .mn-wd-xs-250p {
        min-width: 250%;
    }

    .wd-xs-250-force {
        width: 250px !important;
    }

    .wd-xs-250p-force {
        width: 250% !important;
    }

    .mx-wd-xs-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-xs-250p-force {
        min-width: 250% !important;
    }

    .wd-xs-300 {
        width: 300px;
    }

    .wd-xs-300p {
        width: 300%;
    }

    .mx-wd-xs-300p {
        max-width: 300%;
    }

    .mn-wd-xs-300p {
        min-width: 300%;
    }

    .wd-xs-300-force {
        width: 300px !important;
    }

    .wd-xs-300p-force {
        width: 300% !important;
    }

    .mx-wd-xs-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-xs-300p-force {
        min-width: 300% !important;
    }

    .wd-xs-350 {
        width: 350px;
    }

    .wd-xs-350p {
        width: 350%;
    }

    .mx-wd-xs-350p {
        max-width: 350%;
    }

    .mn-wd-xs-350p {
        min-width: 350%;
    }

    .wd-xs-350-force {
        width: 350px !important;
    }

    .wd-xs-350p-force {
        width: 350% !important;
    }

    .mx-wd-xs-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-xs-350p-force {
        min-width: 350% !important;
    }

    .wd-xs-400 {
        width: 400px;
    }

    .wd-xs-400p {
        width: 400%;
    }

    .mx-wd-xs-400p {
        max-width: 400%;
    }

    .mn-wd-xs-400p {
        min-width: 400%;
    }

    .wd-xs-400-force {
        width: 400px !important;
    }

    .wd-xs-400p-force {
        width: 400% !important;
    }

    .mx-wd-xs-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-xs-400p-force {
        min-width: 400% !important;
    }

    .wd-xs-450 {
        width: 450px;
    }

    .wd-xs-450p {
        width: 450%;
    }

    .mx-wd-xs-450p {
        max-width: 450%;
    }

    .mn-wd-xs-450p {
        min-width: 450%;
    }

    .wd-xs-450-force {
        width: 450px !important;
    }

    .wd-xs-450p-force {
        width: 450% !important;
    }

    .mx-wd-xs-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-xs-450p-force {
        min-width: 450% !important;
    }

    .wd-xs-500 {
        width: 500px;
    }

    .wd-xs-500p {
        width: 500%;
    }

    .mx-wd-xs-500p {
        max-width: 500%;
    }

    .mn-wd-xs-500p {
        min-width: 500%;
    }

    .wd-xs-500-force {
        width: 500px !important;
    }

    .wd-xs-500p-force {
        width: 500% !important;
    }

    .mx-wd-xs-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-xs-500p-force {
        min-width: 500% !important;
    }

    .wd-xs-550 {
        width: 550px;
    }

    .wd-xs-550p {
        width: 550%;
    }

    .mx-wd-xs-550p {
        max-width: 550%;
    }

    .mn-wd-xs-550p {
        min-width: 550%;
    }

    .wd-xs-550-force {
        width: 550px !important;
    }

    .wd-xs-550p-force {
        width: 550% !important;
    }

    .mx-wd-xs-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-xs-550p-force {
        min-width: 550% !important;
    }

    .wd-xs-600 {
        width: 600px;
    }

    .wd-xs-600p {
        width: 600%;
    }

    .mx-wd-xs-600p {
        max-width: 600%;
    }

    .mn-wd-xs-600p {
        min-width: 600%;
    }

    .wd-xs-600-force {
        width: 600px !important;
    }

    .wd-xs-600p-force {
        width: 600% !important;
    }

    .mx-wd-xs-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-xs-600p-force {
        min-width: 600% !important;
    }

    .wd-xs-650 {
        width: 650px;
    }

    .wd-xs-650p {
        width: 650%;
    }

    .mx-wd-xs-650p {
        max-width: 650%;
    }

    .mn-wd-xs-650p {
        min-width: 650%;
    }

    .wd-xs-650-force {
        width: 650px !important;
    }

    .wd-xs-650p-force {
        width: 650% !important;
    }

    .mx-wd-xs-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-xs-650p-force {
        min-width: 650% !important;
    }

    .wd-xs-700 {
        width: 700px;
    }

    .wd-xs-700p {
        width: 700%;
    }

    .mx-wd-xs-700p {
        max-width: 700%;
    }

    .mn-wd-xs-700p {
        min-width: 700%;
    }

    .wd-xs-700-force {
        width: 700px !important;
    }

    .wd-xs-700p-force {
        width: 700% !important;
    }

    .mx-wd-xs-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-xs-700p-force {
        min-width: 700% !important;
    }

    .wd-xs-750 {
        width: 750px;
    }

    .wd-xs-750p {
        width: 750%;
    }

    .mx-wd-xs-750p {
        max-width: 750%;
    }

    .mn-wd-xs-750p {
        min-width: 750%;
    }

    .wd-xs-750-force {
        width: 750px !important;
    }

    .wd-xs-750p-force {
        width: 750% !important;
    }

    .mx-wd-xs-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-xs-750p-force {
        min-width: 750% !important;
    }

    .wd-xs-800 {
        width: 800px;
    }

    .wd-xs-800p {
        width: 800%;
    }

    .mx-wd-xs-800p {
        max-width: 800%;
    }

    .mn-wd-xs-800p {
        min-width: 800%;
    }

    .wd-xs-800-force {
        width: 800px !important;
    }

    .wd-xs-800p-force {
        width: 800% !important;
    }

    .mx-wd-xs-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-xs-800p-force {
        min-width: 800% !important;
    }

    .wd-xs-850 {
        width: 850px;
    }

    .wd-xs-850p {
        width: 850%;
    }

    .mx-wd-xs-850p {
        max-width: 850%;
    }

    .mn-wd-xs-850p {
        min-width: 850%;
    }

    .wd-xs-850-force {
        width: 850px !important;
    }

    .wd-xs-850p-force {
        width: 850% !important;
    }

    .mx-wd-xs-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-xs-850p-force {
        min-width: 850% !important;
    }

    .wd-xs-900 {
        width: 900px;
    }

    .wd-xs-900p {
        width: 900%;
    }

    .mx-wd-xs-900p {
        max-width: 900%;
    }

    .mn-wd-xs-900p {
        min-width: 900%;
    }

    .wd-xs-900-force {
        width: 900px !important;
    }

    .wd-xs-900p-force {
        width: 900% !important;
    }

    .mx-wd-xs-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-xs-900p-force {
        min-width: 900% !important;
    }

    .wd-xs-950 {
        width: 950px;
    }

    .wd-xs-950p {
        width: 950%;
    }

    .mx-wd-xs-950p {
        max-width: 950%;
    }

    .mn-wd-xs-950p {
        min-width: 950%;
    }

    .wd-xs-950-force {
        width: 950px !important;
    }

    .wd-xs-950p-force {
        width: 950% !important;
    }

    .mx-wd-xs-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-xs-950p-force {
        min-width: 950% !important;
    }

    .wd-xs-1000 {
        width: 1000px;
    }

    .wd-xs-1000p {
        width: 1000%;
    }

    .mx-wd-xs-1000p {
        max-width: 1000%;
    }

    .mn-wd-xs-1000p {
        min-width: 1000%;
    }

    .wd-xs-1000-force {
        width: 1000px !important;
    }

    .wd-xs-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-xs-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-xs-1000p-force {
        min-width: 1000% !important;
    }

    .wd-xs-auto {
        width: auto;
    }

    .wd-xs-auto-force {
        width: auto !important;
    }
}
@media (min-width: 576px) {
    .wd-sm-5 {
        width: 5px;
    }

    .wd-sm-5p {
        width: 5%;
    }

    .mx-wd-sm-5p {
        max-width: 5%;
    }

    .mn-wd-sm-5p {
        min-width: 5%;
    }

    .wd-sm-5-force {
        width: 5px !important;
    }

    .wd-sm-5p-force {
        width: 5% !important;
    }

    .mx-wd-sm-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-sm-5p-force {
        min-width: 5% !important;
    }

    .wd-sm-10 {
        width: 10px;
    }

    .wd-sm-10p {
        width: 10%;
    }

    .mx-wd-sm-10p {
        max-width: 10%;
    }

    .mn-wd-sm-10p {
        min-width: 10%;
    }

    .wd-sm-10-force {
        width: 10px !important;
    }

    .wd-sm-10p-force {
        width: 10% !important;
    }

    .mx-wd-sm-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-sm-10p-force {
        min-width: 10% !important;
    }

    .wd-sm-15 {
        width: 15px;
    }

    .wd-sm-15p {
        width: 15%;
    }

    .mx-wd-sm-15p {
        max-width: 15%;
    }

    .mn-wd-sm-15p {
        min-width: 15%;
    }

    .wd-sm-15-force {
        width: 15px !important;
    }

    .wd-sm-15p-force {
        width: 15% !important;
    }

    .mx-wd-sm-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-sm-15p-force {
        min-width: 15% !important;
    }

    .wd-sm-20 {
        width: 20px;
    }

    .wd-sm-20p {
        width: 20%;
    }

    .mx-wd-sm-20p {
        max-width: 20%;
    }

    .mn-wd-sm-20p {
        min-width: 20%;
    }

    .wd-sm-20-force {
        width: 20px !important;
    }

    .wd-sm-20p-force {
        width: 20% !important;
    }

    .mx-wd-sm-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-sm-20p-force {
        min-width: 20% !important;
    }

    .wd-sm-25 {
        width: 25px;
    }

    .wd-sm-25p {
        width: 25%;
    }

    .mx-wd-sm-25p {
        max-width: 25%;
    }

    .mn-wd-sm-25p {
        min-width: 25%;
    }

    .wd-sm-25-force {
        width: 25px !important;
    }

    .wd-sm-25p-force {
        width: 25% !important;
    }

    .mx-wd-sm-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-sm-25p-force {
        min-width: 25% !important;
    }

    .wd-sm-30 {
        width: 30px;
    }

    .wd-sm-30p {
        width: 30%;
    }

    .mx-wd-sm-30p {
        max-width: 30%;
    }

    .mn-wd-sm-30p {
        min-width: 30%;
    }

    .wd-sm-30-force {
        width: 30px !important;
    }

    .wd-sm-30p-force {
        width: 30% !important;
    }

    .mx-wd-sm-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-sm-30p-force {
        min-width: 30% !important;
    }

    .wd-sm-35 {
        width: 35px;
    }

    .wd-sm-35p {
        width: 35%;
    }

    .mx-wd-sm-35p {
        max-width: 35%;
    }

    .mn-wd-sm-35p {
        min-width: 35%;
    }

    .wd-sm-35-force {
        width: 35px !important;
    }

    .wd-sm-35p-force {
        width: 35% !important;
    }

    .mx-wd-sm-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-sm-35p-force {
        min-width: 35% !important;
    }

    .wd-sm-40 {
        width: 40px;
    }

    .wd-sm-40p {
        width: 40%;
    }

    .mx-wd-sm-40p {
        max-width: 40%;
    }

    .mn-wd-sm-40p {
        min-width: 40%;
    }

    .wd-sm-40-force {
        width: 40px !important;
    }

    .wd-sm-40p-force {
        width: 40% !important;
    }

    .mx-wd-sm-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-sm-40p-force {
        min-width: 40% !important;
    }

    .wd-sm-45 {
        width: 45px;
    }

    .wd-sm-45p {
        width: 45%;
    }

    .mx-wd-sm-45p {
        max-width: 45%;
    }

    .mn-wd-sm-45p {
        min-width: 45%;
    }

    .wd-sm-45-force {
        width: 45px !important;
    }

    .wd-sm-45p-force {
        width: 45% !important;
    }

    .mx-wd-sm-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-sm-45p-force {
        min-width: 45% !important;
    }

    .wd-sm-50 {
        width: 50px;
    }

    .wd-sm-50p {
        width: 50%;
    }

    .mx-wd-sm-50p {
        max-width: 50%;
    }

    .mn-wd-sm-50p {
        min-width: 50%;
    }

    .wd-sm-50-force {
        width: 50px !important;
    }

    .wd-sm-50p-force {
        width: 50% !important;
    }

    .mx-wd-sm-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-sm-50p-force {
        min-width: 50% !important;
    }

    .wd-sm-55 {
        width: 55px;
    }

    .wd-sm-55p {
        width: 55%;
    }

    .mx-wd-sm-55p {
        max-width: 55%;
    }

    .mn-wd-sm-55p {
        min-width: 55%;
    }

    .wd-sm-55-force {
        width: 55px !important;
    }

    .wd-sm-55p-force {
        width: 55% !important;
    }

    .mx-wd-sm-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-sm-55p-force {
        min-width: 55% !important;
    }

    .wd-sm-60 {
        width: 60px;
    }

    .wd-sm-60p {
        width: 60%;
    }

    .mx-wd-sm-60p {
        max-width: 60%;
    }

    .mn-wd-sm-60p {
        min-width: 60%;
    }

    .wd-sm-60-force {
        width: 60px !important;
    }

    .wd-sm-60p-force {
        width: 60% !important;
    }

    .mx-wd-sm-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-sm-60p-force {
        min-width: 60% !important;
    }

    .wd-sm-65 {
        width: 65px;
    }

    .wd-sm-65p {
        width: 65%;
    }

    .mx-wd-sm-65p {
        max-width: 65%;
    }

    .mn-wd-sm-65p {
        min-width: 65%;
    }

    .wd-sm-65-force {
        width: 65px !important;
    }

    .wd-sm-65p-force {
        width: 65% !important;
    }

    .mx-wd-sm-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-sm-65p-force {
        min-width: 65% !important;
    }

    .wd-sm-70 {
        width: 70px;
    }

    .wd-sm-70p {
        width: 70%;
    }

    .mx-wd-sm-70p {
        max-width: 70%;
    }

    .mn-wd-sm-70p {
        min-width: 70%;
    }

    .wd-sm-70-force {
        width: 70px !important;
    }

    .wd-sm-70p-force {
        width: 70% !important;
    }

    .mx-wd-sm-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-sm-70p-force {
        min-width: 70% !important;
    }

    .wd-sm-75 {
        width: 75px;
    }

    .wd-sm-75p {
        width: 75%;
    }

    .mx-wd-sm-75p {
        max-width: 75%;
    }

    .mn-wd-sm-75p {
        min-width: 75%;
    }

    .wd-sm-75-force {
        width: 75px !important;
    }

    .wd-sm-75p-force {
        width: 75% !important;
    }

    .mx-wd-sm-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-sm-75p-force {
        min-width: 75% !important;
    }

    .wd-sm-80 {
        width: 80px;
    }

    .wd-sm-80p {
        width: 80%;
    }

    .mx-wd-sm-80p {
        max-width: 80%;
    }

    .mn-wd-sm-80p {
        min-width: 80%;
    }

    .wd-sm-80-force {
        width: 80px !important;
    }

    .wd-sm-80p-force {
        width: 80% !important;
    }

    .mx-wd-sm-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-sm-80p-force {
        min-width: 80% !important;
    }

    .wd-sm-85 {
        width: 85px;
    }

    .wd-sm-85p {
        width: 85%;
    }

    .mx-wd-sm-85p {
        max-width: 85%;
    }

    .mn-wd-sm-85p {
        min-width: 85%;
    }

    .wd-sm-85-force {
        width: 85px !important;
    }

    .wd-sm-85p-force {
        width: 85% !important;
    }

    .mx-wd-sm-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-sm-85p-force {
        min-width: 85% !important;
    }

    .wd-sm-90 {
        width: 90px;
    }

    .wd-sm-90p {
        width: 90%;
    }

    .mx-wd-sm-90p {
        max-width: 90%;
    }

    .mn-wd-sm-90p {
        min-width: 90%;
    }

    .wd-sm-90-force {
        width: 90px !important;
    }

    .wd-sm-90p-force {
        width: 90% !important;
    }

    .mx-wd-sm-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-sm-90p-force {
        min-width: 90% !important;
    }

    .wd-sm-95 {
        width: 95px;
    }

    .wd-sm-95p {
        width: 95%;
    }

    .mx-wd-sm-95p {
        max-width: 95%;
    }

    .mn-wd-sm-95p {
        min-width: 95%;
    }

    .wd-sm-95-force {
        width: 95px !important;
    }

    .wd-sm-95p-force {
        width: 95% !important;
    }

    .mx-wd-sm-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-sm-95p-force {
        min-width: 95% !important;
    }

    .wd-sm-100 {
        width: 100px;
    }

    .wd-sm-100p {
        width: 100%;
    }

    .mx-wd-sm-100p {
        max-width: 100%;
    }

    .mn-wd-sm-100p {
        min-width: 100%;
    }

    .wd-sm-100-force {
        width: 100px !important;
    }

    .wd-sm-100p-force {
        width: 100% !important;
    }

    .mx-wd-sm-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-sm-100p-force {
        min-width: 100% !important;
    }

    .wd-sm-150 {
        width: 150px;
    }

    .wd-sm-150p {
        width: 150%;
    }

    .mx-wd-sm-150p {
        max-width: 150%;
    }

    .mn-wd-sm-150p {
        min-width: 150%;
    }

    .wd-sm-150-force {
        width: 150px !important;
    }

    .wd-sm-150p-force {
        width: 150% !important;
    }

    .mx-wd-sm-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-sm-150p-force {
        min-width: 150% !important;
    }

    .wd-sm-200 {
        width: 200px;
    }

    .wd-sm-200p {
        width: 200%;
    }

    .mx-wd-sm-200p {
        max-width: 200%;
    }

    .mn-wd-sm-200p {
        min-width: 200%;
    }

    .wd-sm-200-force {
        width: 200px !important;
    }

    .wd-sm-200p-force {
        width: 200% !important;
    }

    .mx-wd-sm-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-sm-200p-force {
        min-width: 200% !important;
    }

    .wd-sm-250 {
        width: 250px;
    }

    .wd-sm-250p {
        width: 250%;
    }

    .mx-wd-sm-250p {
        max-width: 250%;
    }

    .mn-wd-sm-250p {
        min-width: 250%;
    }

    .wd-sm-250-force {
        width: 250px !important;
    }

    .wd-sm-250p-force {
        width: 250% !important;
    }

    .mx-wd-sm-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-sm-250p-force {
        min-width: 250% !important;
    }

    .wd-sm-300 {
        width: 300px;
    }

    .wd-sm-300p {
        width: 300%;
    }

    .mx-wd-sm-300p {
        max-width: 300%;
    }

    .mn-wd-sm-300p {
        min-width: 300%;
    }

    .wd-sm-300-force {
        width: 300px !important;
    }

    .wd-sm-300p-force {
        width: 300% !important;
    }

    .mx-wd-sm-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-sm-300p-force {
        min-width: 300% !important;
    }

    .wd-sm-350 {
        width: 350px;
    }

    .wd-sm-350p {
        width: 350%;
    }

    .mx-wd-sm-350p {
        max-width: 350%;
    }

    .mn-wd-sm-350p {
        min-width: 350%;
    }

    .wd-sm-350-force {
        width: 350px !important;
    }

    .wd-sm-350p-force {
        width: 350% !important;
    }

    .mx-wd-sm-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-sm-350p-force {
        min-width: 350% !important;
    }

    .wd-sm-400 {
        width: 400px;
    }

    .wd-sm-400p {
        width: 400%;
    }

    .mx-wd-sm-400p {
        max-width: 400%;
    }

    .mn-wd-sm-400p {
        min-width: 400%;
    }

    .wd-sm-400-force {
        width: 400px !important;
    }

    .wd-sm-400p-force {
        width: 400% !important;
    }

    .mx-wd-sm-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-sm-400p-force {
        min-width: 400% !important;
    }

    .wd-sm-450 {
        width: 450px;
    }

    .wd-sm-450p {
        width: 450%;
    }

    .mx-wd-sm-450p {
        max-width: 450%;
    }

    .mn-wd-sm-450p {
        min-width: 450%;
    }

    .wd-sm-450-force {
        width: 450px !important;
    }

    .wd-sm-450p-force {
        width: 450% !important;
    }

    .mx-wd-sm-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-sm-450p-force {
        min-width: 450% !important;
    }

    .wd-sm-500 {
        width: 500px;
    }

    .wd-sm-500p {
        width: 500%;
    }

    .mx-wd-sm-500p {
        max-width: 500%;
    }

    .mn-wd-sm-500p {
        min-width: 500%;
    }

    .wd-sm-500-force {
        width: 500px !important;
    }

    .wd-sm-500p-force {
        width: 500% !important;
    }

    .mx-wd-sm-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-sm-500p-force {
        min-width: 500% !important;
    }

    .wd-sm-550 {
        width: 550px;
    }

    .wd-sm-550p {
        width: 550%;
    }

    .mx-wd-sm-550p {
        max-width: 550%;
    }

    .mn-wd-sm-550p {
        min-width: 550%;
    }

    .wd-sm-550-force {
        width: 550px !important;
    }

    .wd-sm-550p-force {
        width: 550% !important;
    }

    .mx-wd-sm-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-sm-550p-force {
        min-width: 550% !important;
    }

    .wd-sm-600 {
        width: 600px;
    }

    .wd-sm-600p {
        width: 600%;
    }

    .mx-wd-sm-600p {
        max-width: 600%;
    }

    .mn-wd-sm-600p {
        min-width: 600%;
    }

    .wd-sm-600-force {
        width: 600px !important;
    }

    .wd-sm-600p-force {
        width: 600% !important;
    }

    .mx-wd-sm-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-sm-600p-force {
        min-width: 600% !important;
    }

    .wd-sm-650 {
        width: 650px;
    }

    .wd-sm-650p {
        width: 650%;
    }

    .mx-wd-sm-650p {
        max-width: 650%;
    }

    .mn-wd-sm-650p {
        min-width: 650%;
    }

    .wd-sm-650-force {
        width: 650px !important;
    }

    .wd-sm-650p-force {
        width: 650% !important;
    }

    .mx-wd-sm-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-sm-650p-force {
        min-width: 650% !important;
    }

    .wd-sm-700 {
        width: 700px;
    }

    .wd-sm-700p {
        width: 700%;
    }

    .mx-wd-sm-700p {
        max-width: 700%;
    }

    .mn-wd-sm-700p {
        min-width: 700%;
    }

    .wd-sm-700-force {
        width: 700px !important;
    }

    .wd-sm-700p-force {
        width: 700% !important;
    }

    .mx-wd-sm-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-sm-700p-force {
        min-width: 700% !important;
    }

    .wd-sm-750 {
        width: 750px;
    }

    .wd-sm-750p {
        width: 750%;
    }

    .mx-wd-sm-750p {
        max-width: 750%;
    }

    .mn-wd-sm-750p {
        min-width: 750%;
    }

    .wd-sm-750-force {
        width: 750px !important;
    }

    .wd-sm-750p-force {
        width: 750% !important;
    }

    .mx-wd-sm-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-sm-750p-force {
        min-width: 750% !important;
    }

    .wd-sm-800 {
        width: 800px;
    }

    .wd-sm-800p {
        width: 800%;
    }

    .mx-wd-sm-800p {
        max-width: 800%;
    }

    .mn-wd-sm-800p {
        min-width: 800%;
    }

    .wd-sm-800-force {
        width: 800px !important;
    }

    .wd-sm-800p-force {
        width: 800% !important;
    }

    .mx-wd-sm-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-sm-800p-force {
        min-width: 800% !important;
    }

    .wd-sm-850 {
        width: 850px;
    }

    .wd-sm-850p {
        width: 850%;
    }

    .mx-wd-sm-850p {
        max-width: 850%;
    }

    .mn-wd-sm-850p {
        min-width: 850%;
    }

    .wd-sm-850-force {
        width: 850px !important;
    }

    .wd-sm-850p-force {
        width: 850% !important;
    }

    .mx-wd-sm-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-sm-850p-force {
        min-width: 850% !important;
    }

    .wd-sm-900 {
        width: 900px;
    }

    .wd-sm-900p {
        width: 900%;
    }

    .mx-wd-sm-900p {
        max-width: 900%;
    }

    .mn-wd-sm-900p {
        min-width: 900%;
    }

    .wd-sm-900-force {
        width: 900px !important;
    }

    .wd-sm-900p-force {
        width: 900% !important;
    }

    .mx-wd-sm-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-sm-900p-force {
        min-width: 900% !important;
    }

    .wd-sm-950 {
        width: 950px;
    }

    .wd-sm-950p {
        width: 950%;
    }

    .mx-wd-sm-950p {
        max-width: 950%;
    }

    .mn-wd-sm-950p {
        min-width: 950%;
    }

    .wd-sm-950-force {
        width: 950px !important;
    }

    .wd-sm-950p-force {
        width: 950% !important;
    }

    .mx-wd-sm-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-sm-950p-force {
        min-width: 950% !important;
    }

    .wd-sm-1000 {
        width: 1000px;
    }

    .wd-sm-1000p {
        width: 1000%;
    }

    .mx-wd-sm-1000p {
        max-width: 1000%;
    }

    .mn-wd-sm-1000p {
        min-width: 1000%;
    }

    .wd-sm-1000-force {
        width: 1000px !important;
    }

    .wd-sm-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-sm-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-sm-1000p-force {
        min-width: 1000% !important;
    }

    .wd-sm-auto {
        width: auto;
    }

    .wd-sm-auto-force {
        width: auto !important;
    }
}
@media (min-width: 768px) {
    .wd-md-5 {
        width: 5px;
    }

    .wd-md-5p {
        width: 5%;
    }

    .mx-wd-md-5p {
        max-width: 5%;
    }

    .mn-wd-md-5p {
        min-width: 5%;
    }

    .wd-md-5-force {
        width: 5px !important;
    }

    .wd-md-5p-force {
        width: 5% !important;
    }

    .mx-wd-md-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-md-5p-force {
        min-width: 5% !important;
    }

    .wd-md-10 {
        width: 10px;
    }

    .wd-md-10p {
        width: 10%;
    }

    .mx-wd-md-10p {
        max-width: 10%;
    }

    .mn-wd-md-10p {
        min-width: 10%;
    }

    .wd-md-10-force {
        width: 10px !important;
    }

    .wd-md-10p-force {
        width: 10% !important;
    }

    .mx-wd-md-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-md-10p-force {
        min-width: 10% !important;
    }

    .wd-md-15 {
        width: 15px;
    }

    .wd-md-15p {
        width: 15%;
    }

    .mx-wd-md-15p {
        max-width: 15%;
    }

    .mn-wd-md-15p {
        min-width: 15%;
    }

    .wd-md-15-force {
        width: 15px !important;
    }

    .wd-md-15p-force {
        width: 15% !important;
    }

    .mx-wd-md-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-md-15p-force {
        min-width: 15% !important;
    }

    .wd-md-20 {
        width: 20px;
    }

    .wd-md-20p {
        width: 20%;
    }

    .mx-wd-md-20p {
        max-width: 20%;
    }

    .mn-wd-md-20p {
        min-width: 20%;
    }

    .wd-md-20-force {
        width: 20px !important;
    }

    .wd-md-20p-force {
        width: 20% !important;
    }

    .mx-wd-md-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-md-20p-force {
        min-width: 20% !important;
    }

    .wd-md-25 {
        width: 25px;
    }

    .wd-md-25p {
        width: 25%;
    }

    .mx-wd-md-25p {
        max-width: 25%;
    }

    .mn-wd-md-25p {
        min-width: 25%;
    }

    .wd-md-25-force {
        width: 25px !important;
    }

    .wd-md-25p-force {
        width: 25% !important;
    }

    .mx-wd-md-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-md-25p-force {
        min-width: 25% !important;
    }

    .wd-md-30 {
        width: 30px;
    }

    .wd-md-30p {
        width: 30%;
    }

    .mx-wd-md-30p {
        max-width: 30%;
    }

    .mn-wd-md-30p {
        min-width: 30%;
    }

    .wd-md-30-force {
        width: 30px !important;
    }

    .wd-md-30p-force {
        width: 30% !important;
    }

    .mx-wd-md-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-md-30p-force {
        min-width: 30% !important;
    }

    .wd-md-35 {
        width: 35px;
    }

    .wd-md-35p {
        width: 35%;
    }

    .mx-wd-md-35p {
        max-width: 35%;
    }

    .mn-wd-md-35p {
        min-width: 35%;
    }

    .wd-md-35-force {
        width: 35px !important;
    }

    .wd-md-35p-force {
        width: 35% !important;
    }

    .mx-wd-md-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-md-35p-force {
        min-width: 35% !important;
    }

    .wd-md-40 {
        width: 40px;
    }

    .wd-md-40p {
        width: 40%;
    }

    .mx-wd-md-40p {
        max-width: 40%;
    }

    .mn-wd-md-40p {
        min-width: 40%;
    }

    .wd-md-40-force {
        width: 40px !important;
    }

    .wd-md-40p-force {
        width: 40% !important;
    }

    .mx-wd-md-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-md-40p-force {
        min-width: 40% !important;
    }

    .wd-md-45 {
        width: 45px;
    }

    .wd-md-45p {
        width: 45%;
    }

    .mx-wd-md-45p {
        max-width: 45%;
    }

    .mn-wd-md-45p {
        min-width: 45%;
    }

    .wd-md-45-force {
        width: 45px !important;
    }

    .wd-md-45p-force {
        width: 45% !important;
    }

    .mx-wd-md-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-md-45p-force {
        min-width: 45% !important;
    }

    .wd-md-50 {
        width: 50px;
    }

    .wd-md-50p {
        width: 50%;
    }

    .mx-wd-md-50p {
        max-width: 50%;
    }

    .mn-wd-md-50p {
        min-width: 50%;
    }

    .wd-md-50-force {
        width: 50px !important;
    }

    .wd-md-50p-force {
        width: 50% !important;
    }

    .mx-wd-md-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-md-50p-force {
        min-width: 50% !important;
    }

    .wd-md-55 {
        width: 55px;
    }

    .wd-md-55p {
        width: 55%;
    }

    .mx-wd-md-55p {
        max-width: 55%;
    }

    .mn-wd-md-55p {
        min-width: 55%;
    }

    .wd-md-55-force {
        width: 55px !important;
    }

    .wd-md-55p-force {
        width: 55% !important;
    }

    .mx-wd-md-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-md-55p-force {
        min-width: 55% !important;
    }

    .wd-md-60 {
        width: 60px;
    }

    .wd-md-60p {
        width: 60%;
    }

    .mx-wd-md-60p {
        max-width: 60%;
    }

    .mn-wd-md-60p {
        min-width: 60%;
    }

    .wd-md-60-force {
        width: 60px !important;
    }

    .wd-md-60p-force {
        width: 60% !important;
    }

    .mx-wd-md-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-md-60p-force {
        min-width: 60% !important;
    }

    .wd-md-65 {
        width: 65px;
    }

    .wd-md-65p {
        width: 65%;
    }

    .mx-wd-md-65p {
        max-width: 65%;
    }

    .mn-wd-md-65p {
        min-width: 65%;
    }

    .wd-md-65-force {
        width: 65px !important;
    }

    .wd-md-65p-force {
        width: 65% !important;
    }

    .mx-wd-md-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-md-65p-force {
        min-width: 65% !important;
    }

    .wd-md-70 {
        width: 70px;
    }

    .wd-md-70p {
        width: 70%;
    }

    .mx-wd-md-70p {
        max-width: 70%;
    }

    .mn-wd-md-70p {
        min-width: 70%;
    }

    .wd-md-70-force {
        width: 70px !important;
    }

    .wd-md-70p-force {
        width: 70% !important;
    }

    .mx-wd-md-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-md-70p-force {
        min-width: 70% !important;
    }

    .wd-md-75 {
        width: 75px;
    }

    .wd-md-75p {
        width: 75%;
    }

    .mx-wd-md-75p {
        max-width: 75%;
    }

    .mn-wd-md-75p {
        min-width: 75%;
    }

    .wd-md-75-force {
        width: 75px !important;
    }

    .wd-md-75p-force {
        width: 75% !important;
    }

    .mx-wd-md-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-md-75p-force {
        min-width: 75% !important;
    }

    .wd-md-80 {
        width: 80px;
    }

    .wd-md-80p {
        width: 80%;
    }

    .mx-wd-md-80p {
        max-width: 80%;
    }

    .mn-wd-md-80p {
        min-width: 80%;
    }

    .wd-md-80-force {
        width: 80px !important;
    }

    .wd-md-80p-force {
        width: 80% !important;
    }

    .mx-wd-md-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-md-80p-force {
        min-width: 80% !important;
    }

    .wd-md-85 {
        width: 85px;
    }

    .wd-md-85p {
        width: 85%;
    }

    .mx-wd-md-85p {
        max-width: 85%;
    }

    .mn-wd-md-85p {
        min-width: 85%;
    }

    .wd-md-85-force {
        width: 85px !important;
    }

    .wd-md-85p-force {
        width: 85% !important;
    }

    .mx-wd-md-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-md-85p-force {
        min-width: 85% !important;
    }

    .wd-md-90 {
        width: 90px;
    }

    .wd-md-90p {
        width: 90%;
    }

    .mx-wd-md-90p {
        max-width: 90%;
    }

    .mn-wd-md-90p {
        min-width: 90%;
    }

    .wd-md-90-force {
        width: 90px !important;
    }

    .wd-md-90p-force {
        width: 90% !important;
    }

    .mx-wd-md-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-md-90p-force {
        min-width: 90% !important;
    }

    .wd-md-95 {
        width: 95px;
    }

    .wd-md-95p {
        width: 95%;
    }

    .mx-wd-md-95p {
        max-width: 95%;
    }

    .mn-wd-md-95p {
        min-width: 95%;
    }

    .wd-md-95-force {
        width: 95px !important;
    }

    .wd-md-95p-force {
        width: 95% !important;
    }

    .mx-wd-md-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-md-95p-force {
        min-width: 95% !important;
    }

    .wd-md-100 {
        width: 100px;
    }

    .wd-md-100p {
        width: 100%;
    }

    .mx-wd-md-100p {
        max-width: 100%;
    }

    .mn-wd-md-100p {
        min-width: 100%;
    }

    .wd-md-100-force {
        width: 100px !important;
    }

    .wd-md-100p-force {
        width: 100% !important;
    }

    .mx-wd-md-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-md-100p-force {
        min-width: 100% !important;
    }

    .wd-md-150 {
        width: 150px;
    }

    .wd-md-150p {
        width: 150%;
    }

    .mx-wd-md-150p {
        max-width: 150%;
    }

    .mn-wd-md-150p {
        min-width: 150%;
    }

    .wd-md-150-force {
        width: 150px !important;
    }

    .wd-md-150p-force {
        width: 150% !important;
    }

    .mx-wd-md-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-md-150p-force {
        min-width: 150% !important;
    }

    .wd-md-200 {
        width: 200px;
    }

    .wd-md-200p {
        width: 200%;
    }

    .mx-wd-md-200p {
        max-width: 200%;
    }

    .mn-wd-md-200p {
        min-width: 200%;
    }

    .wd-md-200-force {
        width: 200px !important;
    }

    .wd-md-200p-force {
        width: 200% !important;
    }

    .mx-wd-md-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-md-200p-force {
        min-width: 200% !important;
    }

    .wd-md-250 {
        width: 250px;
    }

    .wd-md-250p {
        width: 250%;
    }

    .mx-wd-md-250p {
        max-width: 250%;
    }

    .mn-wd-md-250p {
        min-width: 250%;
    }

    .wd-md-250-force {
        width: 250px !important;
    }

    .wd-md-250p-force {
        width: 250% !important;
    }

    .mx-wd-md-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-md-250p-force {
        min-width: 250% !important;
    }

    .wd-md-300 {
        width: 300px;
    }

    .wd-md-300p {
        width: 300%;
    }

    .mx-wd-md-300p {
        max-width: 300%;
    }

    .mn-wd-md-300p {
        min-width: 300%;
    }

    .wd-md-300-force {
        width: 300px !important;
    }

    .wd-md-300p-force {
        width: 300% !important;
    }

    .mx-wd-md-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-md-300p-force {
        min-width: 300% !important;
    }

    .wd-md-350 {
        width: 350px;
    }

    .wd-md-350p {
        width: 350%;
    }

    .mx-wd-md-350p {
        max-width: 350%;
    }

    .mn-wd-md-350p {
        min-width: 350%;
    }

    .wd-md-350-force {
        width: 350px !important;
    }

    .wd-md-350p-force {
        width: 350% !important;
    }

    .mx-wd-md-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-md-350p-force {
        min-width: 350% !important;
    }

    .wd-md-400 {
        width: 400px;
    }

    .wd-md-400p {
        width: 400%;
    }

    .mx-wd-md-400p {
        max-width: 400%;
    }

    .mn-wd-md-400p {
        min-width: 400%;
    }

    .wd-md-400-force {
        width: 400px !important;
    }

    .wd-md-400p-force {
        width: 400% !important;
    }

    .mx-wd-md-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-md-400p-force {
        min-width: 400% !important;
    }

    .wd-md-450 {
        width: 450px;
    }

    .wd-md-450p {
        width: 450%;
    }

    .mx-wd-md-450p {
        max-width: 450%;
    }

    .mn-wd-md-450p {
        min-width: 450%;
    }

    .wd-md-450-force {
        width: 450px !important;
    }

    .wd-md-450p-force {
        width: 450% !important;
    }

    .mx-wd-md-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-md-450p-force {
        min-width: 450% !important;
    }

    .wd-md-500 {
        width: 500px;
    }

    .wd-md-500p {
        width: 500%;
    }

    .mx-wd-md-500p {
        max-width: 500%;
    }

    .mn-wd-md-500p {
        min-width: 500%;
    }

    .wd-md-500-force {
        width: 500px !important;
    }

    .wd-md-500p-force {
        width: 500% !important;
    }

    .mx-wd-md-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-md-500p-force {
        min-width: 500% !important;
    }

    .wd-md-550 {
        width: 550px;
    }

    .wd-md-550p {
        width: 550%;
    }

    .mx-wd-md-550p {
        max-width: 550%;
    }

    .mn-wd-md-550p {
        min-width: 550%;
    }

    .wd-md-550-force {
        width: 550px !important;
    }

    .wd-md-550p-force {
        width: 550% !important;
    }

    .mx-wd-md-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-md-550p-force {
        min-width: 550% !important;
    }

    .wd-md-600 {
        width: 600px;
    }

    .wd-md-600p {
        width: 600%;
    }

    .mx-wd-md-600p {
        max-width: 600%;
    }

    .mn-wd-md-600p {
        min-width: 600%;
    }

    .wd-md-600-force {
        width: 600px !important;
    }

    .wd-md-600p-force {
        width: 600% !important;
    }

    .mx-wd-md-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-md-600p-force {
        min-width: 600% !important;
    }

    .wd-md-650 {
        width: 650px;
    }

    .wd-md-650p {
        width: 650%;
    }

    .mx-wd-md-650p {
        max-width: 650%;
    }

    .mn-wd-md-650p {
        min-width: 650%;
    }

    .wd-md-650-force {
        width: 650px !important;
    }

    .wd-md-650p-force {
        width: 650% !important;
    }

    .mx-wd-md-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-md-650p-force {
        min-width: 650% !important;
    }

    .wd-md-700 {
        width: 700px;
    }

    .wd-md-700p {
        width: 700%;
    }

    .mx-wd-md-700p {
        max-width: 700%;
    }

    .mn-wd-md-700p {
        min-width: 700%;
    }

    .wd-md-700-force {
        width: 700px !important;
    }

    .wd-md-700p-force {
        width: 700% !important;
    }

    .mx-wd-md-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-md-700p-force {
        min-width: 700% !important;
    }

    .wd-md-750 {
        width: 750px;
    }

    .wd-md-750p {
        width: 750%;
    }

    .mx-wd-md-750p {
        max-width: 750%;
    }

    .mn-wd-md-750p {
        min-width: 750%;
    }

    .wd-md-750-force {
        width: 750px !important;
    }

    .wd-md-750p-force {
        width: 750% !important;
    }

    .mx-wd-md-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-md-750p-force {
        min-width: 750% !important;
    }

    .wd-md-800 {
        width: 800px;
    }

    .wd-md-800p {
        width: 800%;
    }

    .mx-wd-md-800p {
        max-width: 800%;
    }

    .mn-wd-md-800p {
        min-width: 800%;
    }

    .wd-md-800-force {
        width: 800px !important;
    }

    .wd-md-800p-force {
        width: 800% !important;
    }

    .mx-wd-md-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-md-800p-force {
        min-width: 800% !important;
    }

    .wd-md-850 {
        width: 850px;
    }

    .wd-md-850p {
        width: 850%;
    }

    .mx-wd-md-850p {
        max-width: 850%;
    }

    .mn-wd-md-850p {
        min-width: 850%;
    }

    .wd-md-850-force {
        width: 850px !important;
    }

    .wd-md-850p-force {
        width: 850% !important;
    }

    .mx-wd-md-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-md-850p-force {
        min-width: 850% !important;
    }

    .wd-md-900 {
        width: 900px;
    }

    .wd-md-900p {
        width: 900%;
    }

    .mx-wd-md-900p {
        max-width: 900%;
    }

    .mn-wd-md-900p {
        min-width: 900%;
    }

    .wd-md-900-force {
        width: 900px !important;
    }

    .wd-md-900p-force {
        width: 900% !important;
    }

    .mx-wd-md-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-md-900p-force {
        min-width: 900% !important;
    }

    .wd-md-950 {
        width: 950px;
    }

    .wd-md-950p {
        width: 950%;
    }

    .mx-wd-md-950p {
        max-width: 950%;
    }

    .mn-wd-md-950p {
        min-width: 950%;
    }

    .wd-md-950-force {
        width: 950px !important;
    }

    .wd-md-950p-force {
        width: 950% !important;
    }

    .mx-wd-md-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-md-950p-force {
        min-width: 950% !important;
    }

    .wd-md-1000 {
        width: 1000px;
    }

    .wd-md-1000p {
        width: 1000%;
    }

    .mx-wd-md-1000p {
        max-width: 1000%;
    }

    .mn-wd-md-1000p {
        min-width: 1000%;
    }

    .wd-md-1000-force {
        width: 1000px !important;
    }

    .wd-md-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-md-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-md-1000p-force {
        min-width: 1000% !important;
    }

    .wd-md-auto {
        width: auto;
    }

    .wd-md-auto-force {
        width: auto !important;
    }
}
@media (min-width: 992px) {
    .wd-lg-5 {
        width: 5px;
    }

    .wd-lg-5p {
        width: 5%;
    }

    .mx-wd-lg-5p {
        max-width: 5%;
    }

    .mn-wd-lg-5p {
        min-width: 5%;
    }

    .wd-lg-5-force {
        width: 5px !important;
    }

    .wd-lg-5p-force {
        width: 5% !important;
    }

    .mx-wd-lg-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-lg-5p-force {
        min-width: 5% !important;
    }

    .wd-lg-10 {
        width: 10px;
    }

    .wd-lg-10p {
        width: 10%;
    }

    .mx-wd-lg-10p {
        max-width: 10%;
    }

    .mn-wd-lg-10p {
        min-width: 10%;
    }

    .wd-lg-10-force {
        width: 10px !important;
    }

    .wd-lg-10p-force {
        width: 10% !important;
    }

    .mx-wd-lg-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-lg-10p-force {
        min-width: 10% !important;
    }

    .wd-lg-15 {
        width: 15px;
    }

    .wd-lg-15p {
        width: 15%;
    }

    .mx-wd-lg-15p {
        max-width: 15%;
    }

    .mn-wd-lg-15p {
        min-width: 15%;
    }

    .wd-lg-15-force {
        width: 15px !important;
    }

    .wd-lg-15p-force {
        width: 15% !important;
    }

    .mx-wd-lg-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-lg-15p-force {
        min-width: 15% !important;
    }

    .wd-lg-20 {
        width: 20px;
    }

    .wd-lg-20p {
        width: 20%;
    }

    .mx-wd-lg-20p {
        max-width: 20%;
    }

    .mn-wd-lg-20p {
        min-width: 20%;
    }

    .wd-lg-20-force {
        width: 20px !important;
    }

    .wd-lg-20p-force {
        width: 20% !important;
    }

    .mx-wd-lg-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-lg-20p-force {
        min-width: 20% !important;
    }

    .wd-lg-25 {
        width: 25px;
    }

    .wd-lg-25p {
        width: 25%;
    }

    .mx-wd-lg-25p {
        max-width: 25%;
    }

    .mn-wd-lg-25p {
        min-width: 25%;
    }

    .wd-lg-25-force {
        width: 25px !important;
    }

    .wd-lg-25p-force {
        width: 25% !important;
    }

    .mx-wd-lg-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-lg-25p-force {
        min-width: 25% !important;
    }

    .wd-lg-30 {
        width: 30px;
    }

    .wd-lg-30p {
        width: 30%;
    }

    .mx-wd-lg-30p {
        max-width: 30%;
    }

    .mn-wd-lg-30p {
        min-width: 30%;
    }

    .wd-lg-30-force {
        width: 30px !important;
    }

    .wd-lg-30p-force {
        width: 30% !important;
    }

    .mx-wd-lg-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-lg-30p-force {
        min-width: 30% !important;
    }

    .wd-lg-35 {
        width: 35px;
    }

    .wd-lg-35p {
        width: 35%;
    }

    .mx-wd-lg-35p {
        max-width: 35%;
    }

    .mn-wd-lg-35p {
        min-width: 35%;
    }

    .wd-lg-35-force {
        width: 35px !important;
    }

    .wd-lg-35p-force {
        width: 35% !important;
    }

    .mx-wd-lg-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-lg-35p-force {
        min-width: 35% !important;
    }

    .wd-lg-40 {
        width: 40px;
    }

    .wd-lg-40p {
        width: 40%;
    }

    .mx-wd-lg-40p {
        max-width: 40%;
    }

    .mn-wd-lg-40p {
        min-width: 40%;
    }

    .wd-lg-40-force {
        width: 40px !important;
    }

    .wd-lg-40p-force {
        width: 40% !important;
    }

    .mx-wd-lg-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-lg-40p-force {
        min-width: 40% !important;
    }

    .wd-lg-45 {
        width: 45px;
    }

    .wd-lg-45p {
        width: 45%;
    }

    .mx-wd-lg-45p {
        max-width: 45%;
    }

    .mn-wd-lg-45p {
        min-width: 45%;
    }

    .wd-lg-45-force {
        width: 45px !important;
    }

    .wd-lg-45p-force {
        width: 45% !important;
    }

    .mx-wd-lg-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-lg-45p-force {
        min-width: 45% !important;
    }

    .wd-lg-50 {
        width: 50px;
    }

    .wd-lg-50p {
        width: 50%;
    }

    .mx-wd-lg-50p {
        max-width: 50%;
    }

    .mn-wd-lg-50p {
        min-width: 50%;
    }

    .wd-lg-50-force {
        width: 50px !important;
    }

    .wd-lg-50p-force {
        width: 50% !important;
    }

    .mx-wd-lg-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-lg-50p-force {
        min-width: 50% !important;
    }

    .wd-lg-55 {
        width: 55px;
    }

    .wd-lg-55p {
        width: 55%;
    }

    .mx-wd-lg-55p {
        max-width: 55%;
    }

    .mn-wd-lg-55p {
        min-width: 55%;
    }

    .wd-lg-55-force {
        width: 55px !important;
    }

    .wd-lg-55p-force {
        width: 55% !important;
    }

    .mx-wd-lg-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-lg-55p-force {
        min-width: 55% !important;
    }

    .wd-lg-60 {
        width: 60px;
    }

    .wd-lg-60p {
        width: 60%;
    }

    .mx-wd-lg-60p {
        max-width: 60%;
    }

    .mn-wd-lg-60p {
        min-width: 60%;
    }

    .wd-lg-60-force {
        width: 60px !important;
    }

    .wd-lg-60p-force {
        width: 60% !important;
    }

    .mx-wd-lg-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-lg-60p-force {
        min-width: 60% !important;
    }

    .wd-lg-65 {
        width: 65px;
    }

    .wd-lg-65p {
        width: 65%;
    }

    .mx-wd-lg-65p {
        max-width: 65%;
    }

    .mn-wd-lg-65p {
        min-width: 65%;
    }

    .wd-lg-65-force {
        width: 65px !important;
    }

    .wd-lg-65p-force {
        width: 65% !important;
    }

    .mx-wd-lg-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-lg-65p-force {
        min-width: 65% !important;
    }

    .wd-lg-70 {
        width: 70px;
    }

    .wd-lg-70p {
        width: 70%;
    }

    .mx-wd-lg-70p {
        max-width: 70%;
    }

    .mn-wd-lg-70p {
        min-width: 70%;
    }

    .wd-lg-70-force {
        width: 70px !important;
    }

    .wd-lg-70p-force {
        width: 70% !important;
    }

    .mx-wd-lg-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-lg-70p-force {
        min-width: 70% !important;
    }

    .wd-lg-75 {
        width: 75px;
    }

    .wd-lg-75p {
        width: 75%;
    }

    .mx-wd-lg-75p {
        max-width: 75%;
    }

    .mn-wd-lg-75p {
        min-width: 75%;
    }

    .wd-lg-75-force {
        width: 75px !important;
    }

    .wd-lg-75p-force {
        width: 75% !important;
    }

    .mx-wd-lg-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-lg-75p-force {
        min-width: 75% !important;
    }

    .wd-lg-80 {
        width: 80px;
    }

    .wd-lg-80p {
        width: 80%;
    }

    .mx-wd-lg-80p {
        max-width: 80%;
    }

    .mn-wd-lg-80p {
        min-width: 80%;
    }

    .wd-lg-80-force {
        width: 80px !important;
    }

    .wd-lg-80p-force {
        width: 80% !important;
    }

    .mx-wd-lg-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-lg-80p-force {
        min-width: 80% !important;
    }

    .wd-lg-85 {
        width: 85px;
    }

    .wd-lg-85p {
        width: 85%;
    }

    .mx-wd-lg-85p {
        max-width: 85%;
    }

    .mn-wd-lg-85p {
        min-width: 85%;
    }

    .wd-lg-85-force {
        width: 85px !important;
    }

    .wd-lg-85p-force {
        width: 85% !important;
    }

    .mx-wd-lg-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-lg-85p-force {
        min-width: 85% !important;
    }

    .wd-lg-90 {
        width: 90px;
    }

    .wd-lg-90p {
        width: 90%;
    }

    .mx-wd-lg-90p {
        max-width: 90%;
    }

    .mn-wd-lg-90p {
        min-width: 90%;
    }

    .wd-lg-90-force {
        width: 90px !important;
    }

    .wd-lg-90p-force {
        width: 90% !important;
    }

    .mx-wd-lg-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-lg-90p-force {
        min-width: 90% !important;
    }

    .wd-lg-95 {
        width: 95px;
    }

    .wd-lg-95p {
        width: 95%;
    }

    .mx-wd-lg-95p {
        max-width: 95%;
    }

    .mn-wd-lg-95p {
        min-width: 95%;
    }

    .wd-lg-95-force {
        width: 95px !important;
    }

    .wd-lg-95p-force {
        width: 95% !important;
    }

    .mx-wd-lg-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-lg-95p-force {
        min-width: 95% !important;
    }

    .wd-lg-100 {
        width: 100px;
    }

    .wd-lg-100p {
        width: 100%;
    }

    .mx-wd-lg-100p {
        max-width: 100%;
    }

    .mn-wd-lg-100p {
        min-width: 100%;
    }

    .wd-lg-100-force {
        width: 100px !important;
    }

    .wd-lg-100p-force {
        width: 100% !important;
    }

    .mx-wd-lg-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-lg-100p-force {
        min-width: 100% !important;
    }

    .wd-lg-150 {
        width: 150px;
    }

    .wd-lg-150p {
        width: 150%;
    }

    .mx-wd-lg-150p {
        max-width: 150%;
    }

    .mn-wd-lg-150p {
        min-width: 150%;
    }

    .wd-lg-150-force {
        width: 150px !important;
    }

    .wd-lg-150p-force {
        width: 150% !important;
    }

    .mx-wd-lg-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-lg-150p-force {
        min-width: 150% !important;
    }

    .wd-lg-200 {
        width: 200px;
    }

    .wd-lg-200p {
        width: 200%;
    }

    .mx-wd-lg-200p {
        max-width: 200%;
    }

    .mn-wd-lg-200p {
        min-width: 200%;
    }

    .wd-lg-200-force {
        width: 200px !important;
    }

    .wd-lg-200p-force {
        width: 200% !important;
    }

    .mx-wd-lg-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-lg-200p-force {
        min-width: 200% !important;
    }

    .wd-lg-250 {
        width: 250px;
    }

    .wd-lg-250p {
        width: 250%;
    }

    .mx-wd-lg-250p {
        max-width: 250%;
    }

    .mn-wd-lg-250p {
        min-width: 250%;
    }

    .wd-lg-250-force {
        width: 250px !important;
    }

    .wd-lg-250p-force {
        width: 250% !important;
    }

    .mx-wd-lg-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-lg-250p-force {
        min-width: 250% !important;
    }

    .wd-lg-300 {
        width: 300px;
    }

    .wd-lg-300p {
        width: 300%;
    }

    .mx-wd-lg-300p {
        max-width: 300%;
    }

    .mn-wd-lg-300p {
        min-width: 300%;
    }

    .wd-lg-300-force {
        width: 300px !important;
    }

    .wd-lg-300p-force {
        width: 300% !important;
    }

    .mx-wd-lg-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-lg-300p-force {
        min-width: 300% !important;
    }

    .wd-lg-350 {
        width: 350px;
    }

    .wd-lg-350p {
        width: 350%;
    }

    .mx-wd-lg-350p {
        max-width: 350%;
    }

    .mn-wd-lg-350p {
        min-width: 350%;
    }

    .wd-lg-350-force {
        width: 350px !important;
    }

    .wd-lg-350p-force {
        width: 350% !important;
    }

    .mx-wd-lg-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-lg-350p-force {
        min-width: 350% !important;
    }

    .wd-lg-400 {
        width: 400px;
    }

    .wd-lg-400p {
        width: 400%;
    }

    .mx-wd-lg-400p {
        max-width: 400%;
    }

    .mn-wd-lg-400p {
        min-width: 400%;
    }

    .wd-lg-400-force {
        width: 400px !important;
    }

    .wd-lg-400p-force {
        width: 400% !important;
    }

    .mx-wd-lg-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-lg-400p-force {
        min-width: 400% !important;
    }

    .wd-lg-450 {
        width: 450px;
    }

    .wd-lg-450p {
        width: 450%;
    }

    .mx-wd-lg-450p {
        max-width: 450%;
    }

    .mn-wd-lg-450p {
        min-width: 450%;
    }

    .wd-lg-450-force {
        width: 450px !important;
    }

    .wd-lg-450p-force {
        width: 450% !important;
    }

    .mx-wd-lg-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-lg-450p-force {
        min-width: 450% !important;
    }

    .wd-lg-500 {
        width: 500px;
    }

    .wd-lg-500p {
        width: 500%;
    }

    .mx-wd-lg-500p {
        max-width: 500%;
    }

    .mn-wd-lg-500p {
        min-width: 500%;
    }

    .wd-lg-500-force {
        width: 500px !important;
    }

    .wd-lg-500p-force {
        width: 500% !important;
    }

    .mx-wd-lg-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-lg-500p-force {
        min-width: 500% !important;
    }

    .wd-lg-550 {
        width: 550px;
    }

    .wd-lg-550p {
        width: 550%;
    }

    .mx-wd-lg-550p {
        max-width: 550%;
    }

    .mn-wd-lg-550p {
        min-width: 550%;
    }

    .wd-lg-550-force {
        width: 550px !important;
    }

    .wd-lg-550p-force {
        width: 550% !important;
    }

    .mx-wd-lg-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-lg-550p-force {
        min-width: 550% !important;
    }

    .wd-lg-600 {
        width: 600px;
    }

    .wd-lg-600p {
        width: 600%;
    }

    .mx-wd-lg-600p {
        max-width: 600%;
    }

    .mn-wd-lg-600p {
        min-width: 600%;
    }

    .wd-lg-600-force {
        width: 600px !important;
    }

    .wd-lg-600p-force {
        width: 600% !important;
    }

    .mx-wd-lg-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-lg-600p-force {
        min-width: 600% !important;
    }

    .wd-lg-650 {
        width: 650px;
    }

    .wd-lg-650p {
        width: 650%;
    }

    .mx-wd-lg-650p {
        max-width: 650%;
    }

    .mn-wd-lg-650p {
        min-width: 650%;
    }

    .wd-lg-650-force {
        width: 650px !important;
    }

    .wd-lg-650p-force {
        width: 650% !important;
    }

    .mx-wd-lg-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-lg-650p-force {
        min-width: 650% !important;
    }

    .wd-lg-700 {
        width: 700px;
    }

    .wd-lg-700p {
        width: 700%;
    }

    .mx-wd-lg-700p {
        max-width: 700%;
    }

    .mn-wd-lg-700p {
        min-width: 700%;
    }

    .wd-lg-700-force {
        width: 700px !important;
    }

    .wd-lg-700p-force {
        width: 700% !important;
    }

    .mx-wd-lg-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-lg-700p-force {
        min-width: 700% !important;
    }

    .wd-lg-750 {
        width: 750px;
    }

    .wd-lg-750p {
        width: 750%;
    }

    .mx-wd-lg-750p {
        max-width: 750%;
    }

    .mn-wd-lg-750p {
        min-width: 750%;
    }

    .wd-lg-750-force {
        width: 750px !important;
    }

    .wd-lg-750p-force {
        width: 750% !important;
    }

    .mx-wd-lg-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-lg-750p-force {
        min-width: 750% !important;
    }

    .wd-lg-800 {
        width: 800px;
    }

    .wd-lg-800p {
        width: 800%;
    }

    .mx-wd-lg-800p {
        max-width: 800%;
    }

    .mn-wd-lg-800p {
        min-width: 800%;
    }

    .wd-lg-800-force {
        width: 800px !important;
    }

    .wd-lg-800p-force {
        width: 800% !important;
    }

    .mx-wd-lg-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-lg-800p-force {
        min-width: 800% !important;
    }

    .wd-lg-850 {
        width: 850px;
    }

    .wd-lg-850p {
        width: 850%;
    }

    .mx-wd-lg-850p {
        max-width: 850%;
    }

    .mn-wd-lg-850p {
        min-width: 850%;
    }

    .wd-lg-850-force {
        width: 850px !important;
    }

    .wd-lg-850p-force {
        width: 850% !important;
    }

    .mx-wd-lg-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-lg-850p-force {
        min-width: 850% !important;
    }

    .wd-lg-900 {
        width: 900px;
    }

    .wd-lg-900p {
        width: 900%;
    }

    .mx-wd-lg-900p {
        max-width: 900%;
    }

    .mn-wd-lg-900p {
        min-width: 900%;
    }

    .wd-lg-900-force {
        width: 900px !important;
    }

    .wd-lg-900p-force {
        width: 900% !important;
    }

    .mx-wd-lg-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-lg-900p-force {
        min-width: 900% !important;
    }

    .wd-lg-950 {
        width: 950px;
    }

    .wd-lg-950p {
        width: 950%;
    }

    .mx-wd-lg-950p {
        max-width: 950%;
    }

    .mn-wd-lg-950p {
        min-width: 950%;
    }

    .wd-lg-950-force {
        width: 950px !important;
    }

    .wd-lg-950p-force {
        width: 950% !important;
    }

    .mx-wd-lg-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-lg-950p-force {
        min-width: 950% !important;
    }

    .wd-lg-1000 {
        width: 1000px;
    }

    .wd-lg-1000p {
        width: 1000%;
    }

    .mx-wd-lg-1000p {
        max-width: 1000%;
    }

    .mn-wd-lg-1000p {
        min-width: 1000%;
    }

    .wd-lg-1000-force {
        width: 1000px !important;
    }

    .wd-lg-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-lg-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-lg-1000p-force {
        min-width: 1000% !important;
    }

    .wd-lg-auto {
        width: auto;
    }

    .wd-lg-auto-force {
        width: auto !important;
    }
}
@media (min-width: 1200px) {
    .wd-xl-5 {
        width: 5px;
    }

    .wd-xl-5p {
        width: 5%;
    }

    .mx-wd-xl-5p {
        max-width: 5%;
    }

    .mn-wd-xl-5p {
        min-width: 5%;
    }

    .wd-xl-5-force {
        width: 5px !important;
    }

    .wd-xl-5p-force {
        width: 5% !important;
    }

    .mx-wd-xl-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-xl-5p-force {
        min-width: 5% !important;
    }

    .wd-xl-10 {
        width: 10px;
    }

    .wd-xl-10p {
        width: 10%;
    }

    .mx-wd-xl-10p {
        max-width: 10%;
    }

    .mn-wd-xl-10p {
        min-width: 10%;
    }

    .wd-xl-10-force {
        width: 10px !important;
    }

    .wd-xl-10p-force {
        width: 10% !important;
    }

    .mx-wd-xl-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-xl-10p-force {
        min-width: 10% !important;
    }

    .wd-xl-15 {
        width: 15px;
    }

    .wd-xl-15p {
        width: 15%;
    }

    .mx-wd-xl-15p {
        max-width: 15%;
    }

    .mn-wd-xl-15p {
        min-width: 15%;
    }

    .wd-xl-15-force {
        width: 15px !important;
    }

    .wd-xl-15p-force {
        width: 15% !important;
    }

    .mx-wd-xl-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-xl-15p-force {
        min-width: 15% !important;
    }

    .wd-xl-20 {
        width: 20px;
    }

    .wd-xl-20p {
        width: 20%;
    }

    .mx-wd-xl-20p {
        max-width: 20%;
    }

    .mn-wd-xl-20p {
        min-width: 20%;
    }

    .wd-xl-20-force {
        width: 20px !important;
    }

    .wd-xl-20p-force {
        width: 20% !important;
    }

    .mx-wd-xl-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-xl-20p-force {
        min-width: 20% !important;
    }

    .wd-xl-25 {
        width: 25px;
    }

    .wd-xl-25p {
        width: 25%;
    }

    .mx-wd-xl-25p {
        max-width: 25%;
    }

    .mn-wd-xl-25p {
        min-width: 25%;
    }

    .wd-xl-25-force {
        width: 25px !important;
    }

    .wd-xl-25p-force {
        width: 25% !important;
    }

    .mx-wd-xl-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-xl-25p-force {
        min-width: 25% !important;
    }

    .wd-xl-30 {
        width: 30px;
    }

    .wd-xl-30p {
        width: 30%;
    }

    .mx-wd-xl-30p {
        max-width: 30%;
    }

    .mn-wd-xl-30p {
        min-width: 30%;
    }

    .wd-xl-30-force {
        width: 30px !important;
    }

    .wd-xl-30p-force {
        width: 30% !important;
    }

    .mx-wd-xl-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-xl-30p-force {
        min-width: 30% !important;
    }

    .wd-xl-35 {
        width: 35px;
    }

    .wd-xl-35p {
        width: 35%;
    }

    .mx-wd-xl-35p {
        max-width: 35%;
    }

    .mn-wd-xl-35p {
        min-width: 35%;
    }

    .wd-xl-35-force {
        width: 35px !important;
    }

    .wd-xl-35p-force {
        width: 35% !important;
    }

    .mx-wd-xl-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-xl-35p-force {
        min-width: 35% !important;
    }

    .wd-xl-40 {
        width: 40px;
    }

    .wd-xl-40p {
        width: 40%;
    }

    .mx-wd-xl-40p {
        max-width: 40%;
    }

    .mn-wd-xl-40p {
        min-width: 40%;
    }

    .wd-xl-40-force {
        width: 40px !important;
    }

    .wd-xl-40p-force {
        width: 40% !important;
    }

    .mx-wd-xl-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-xl-40p-force {
        min-width: 40% !important;
    }

    .wd-xl-45 {
        width: 45px;
    }

    .wd-xl-45p {
        width: 45%;
    }

    .mx-wd-xl-45p {
        max-width: 45%;
    }

    .mn-wd-xl-45p {
        min-width: 45%;
    }

    .wd-xl-45-force {
        width: 45px !important;
    }

    .wd-xl-45p-force {
        width: 45% !important;
    }

    .mx-wd-xl-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-xl-45p-force {
        min-width: 45% !important;
    }

    .wd-xl-50 {
        width: 50px;
    }

    .wd-xl-50p {
        width: 50%;
    }

    .mx-wd-xl-50p {
        max-width: 50%;
    }

    .mn-wd-xl-50p {
        min-width: 50%;
    }

    .wd-xl-50-force {
        width: 50px !important;
    }

    .wd-xl-50p-force {
        width: 50% !important;
    }

    .mx-wd-xl-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-xl-50p-force {
        min-width: 50% !important;
    }

    .wd-xl-55 {
        width: 55px;
    }

    .wd-xl-55p {
        width: 55%;
    }

    .mx-wd-xl-55p {
        max-width: 55%;
    }

    .mn-wd-xl-55p {
        min-width: 55%;
    }

    .wd-xl-55-force {
        width: 55px !important;
    }

    .wd-xl-55p-force {
        width: 55% !important;
    }

    .mx-wd-xl-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-xl-55p-force {
        min-width: 55% !important;
    }

    .wd-xl-60 {
        width: 60px;
    }

    .wd-xl-60p {
        width: 60%;
    }

    .mx-wd-xl-60p {
        max-width: 60%;
    }

    .mn-wd-xl-60p {
        min-width: 60%;
    }

    .wd-xl-60-force {
        width: 60px !important;
    }

    .wd-xl-60p-force {
        width: 60% !important;
    }

    .mx-wd-xl-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-xl-60p-force {
        min-width: 60% !important;
    }

    .wd-xl-65 {
        width: 65px;
    }

    .wd-xl-65p {
        width: 65%;
    }

    .mx-wd-xl-65p {
        max-width: 65%;
    }

    .mn-wd-xl-65p {
        min-width: 65%;
    }

    .wd-xl-65-force {
        width: 65px !important;
    }

    .wd-xl-65p-force {
        width: 65% !important;
    }

    .mx-wd-xl-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-xl-65p-force {
        min-width: 65% !important;
    }

    .wd-xl-70 {
        width: 70px;
    }

    .wd-xl-70p {
        width: 70%;
    }

    .mx-wd-xl-70p {
        max-width: 70%;
    }

    .mn-wd-xl-70p {
        min-width: 70%;
    }

    .wd-xl-70-force {
        width: 70px !important;
    }

    .wd-xl-70p-force {
        width: 70% !important;
    }

    .mx-wd-xl-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-xl-70p-force {
        min-width: 70% !important;
    }

    .wd-xl-75 {
        width: 75px;
    }

    .wd-xl-75p {
        width: 75%;
    }

    .mx-wd-xl-75p {
        max-width: 75%;
    }

    .mn-wd-xl-75p {
        min-width: 75%;
    }

    .wd-xl-75-force {
        width: 75px !important;
    }

    .wd-xl-75p-force {
        width: 75% !important;
    }

    .mx-wd-xl-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-xl-75p-force {
        min-width: 75% !important;
    }

    .wd-xl-80 {
        width: 80px;
    }

    .wd-xl-80p {
        width: 80%;
    }

    .mx-wd-xl-80p {
        max-width: 80%;
    }

    .mn-wd-xl-80p {
        min-width: 80%;
    }

    .wd-xl-80-force {
        width: 80px !important;
    }

    .wd-xl-80p-force {
        width: 80% !important;
    }

    .mx-wd-xl-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-xl-80p-force {
        min-width: 80% !important;
    }

    .wd-xl-85 {
        width: 85px;
    }

    .wd-xl-85p {
        width: 85%;
    }

    .mx-wd-xl-85p {
        max-width: 85%;
    }

    .mn-wd-xl-85p {
        min-width: 85%;
    }

    .wd-xl-85-force {
        width: 85px !important;
    }

    .wd-xl-85p-force {
        width: 85% !important;
    }

    .mx-wd-xl-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-xl-85p-force {
        min-width: 85% !important;
    }

    .wd-xl-90 {
        width: 90px;
    }

    .wd-xl-90p {
        width: 90%;
    }

    .mx-wd-xl-90p {
        max-width: 90%;
    }

    .mn-wd-xl-90p {
        min-width: 90%;
    }

    .wd-xl-90-force {
        width: 90px !important;
    }

    .wd-xl-90p-force {
        width: 90% !important;
    }

    .mx-wd-xl-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-xl-90p-force {
        min-width: 90% !important;
    }

    .wd-xl-95 {
        width: 95px;
    }

    .wd-xl-95p {
        width: 95%;
    }

    .mx-wd-xl-95p {
        max-width: 95%;
    }

    .mn-wd-xl-95p {
        min-width: 95%;
    }

    .wd-xl-95-force {
        width: 95px !important;
    }

    .wd-xl-95p-force {
        width: 95% !important;
    }

    .mx-wd-xl-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-xl-95p-force {
        min-width: 95% !important;
    }

    .wd-xl-100 {
        width: 100px;
    }

    .wd-xl-100p {
        width: 100%;
    }

    .mx-wd-xl-100p {
        max-width: 100%;
    }

    .mn-wd-xl-100p {
        min-width: 100%;
    }

    .wd-xl-100-force {
        width: 100px !important;
    }

    .wd-xl-100p-force {
        width: 100% !important;
    }

    .mx-wd-xl-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-xl-100p-force {
        min-width: 100% !important;
    }

    .wd-xl-150 {
        width: 150px;
    }

    .wd-xl-150p {
        width: 150%;
    }

    .mx-wd-xl-150p {
        max-width: 150%;
    }

    .mn-wd-xl-150p {
        min-width: 150%;
    }

    .wd-xl-150-force {
        width: 150px !important;
    }

    .wd-xl-150p-force {
        width: 150% !important;
    }

    .mx-wd-xl-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-xl-150p-force {
        min-width: 150% !important;
    }

    .wd-xl-200 {
        width: 200px;
    }

    .wd-xl-200p {
        width: 200%;
    }

    .mx-wd-xl-200p {
        max-width: 200%;
    }

    .mn-wd-xl-200p {
        min-width: 200%;
    }

    .wd-xl-200-force {
        width: 200px !important;
    }

    .wd-xl-200p-force {
        width: 200% !important;
    }

    .mx-wd-xl-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-xl-200p-force {
        min-width: 200% !important;
    }

    .wd-xl-250 {
        width: 250px;
    }

    .wd-xl-250p {
        width: 250%;
    }

    .mx-wd-xl-250p {
        max-width: 250%;
    }

    .mn-wd-xl-250p {
        min-width: 250%;
    }

    .wd-xl-250-force {
        width: 250px !important;
    }

    .wd-xl-250p-force {
        width: 250% !important;
    }

    .mx-wd-xl-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-xl-250p-force {
        min-width: 250% !important;
    }

    .wd-xl-300 {
        width: 300px;
    }

    .wd-xl-300p {
        width: 300%;
    }

    .mx-wd-xl-300p {
        max-width: 300%;
    }

    .mn-wd-xl-300p {
        min-width: 300%;
    }

    .wd-xl-300-force {
        width: 300px !important;
    }

    .wd-xl-300p-force {
        width: 300% !important;
    }

    .mx-wd-xl-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-xl-300p-force {
        min-width: 300% !important;
    }

    .wd-xl-350 {
        width: 350px;
    }

    .wd-xl-350p {
        width: 350%;
    }

    .mx-wd-xl-350p {
        max-width: 350%;
    }

    .mn-wd-xl-350p {
        min-width: 350%;
    }

    .wd-xl-350-force {
        width: 350px !important;
    }

    .wd-xl-350p-force {
        width: 350% !important;
    }

    .mx-wd-xl-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-xl-350p-force {
        min-width: 350% !important;
    }

    .wd-xl-400 {
        width: 400px;
    }

    .wd-xl-400p {
        width: 400%;
    }

    .mx-wd-xl-400p {
        max-width: 400%;
    }

    .mn-wd-xl-400p {
        min-width: 400%;
    }

    .wd-xl-400-force {
        width: 400px !important;
    }

    .wd-xl-400p-force {
        width: 400% !important;
    }

    .mx-wd-xl-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-xl-400p-force {
        min-width: 400% !important;
    }

    .wd-xl-450 {
        width: 450px;
    }

    .wd-xl-450p {
        width: 450%;
    }

    .mx-wd-xl-450p {
        max-width: 450%;
    }

    .mn-wd-xl-450p {
        min-width: 450%;
    }

    .wd-xl-450-force {
        width: 450px !important;
    }

    .wd-xl-450p-force {
        width: 450% !important;
    }

    .mx-wd-xl-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-xl-450p-force {
        min-width: 450% !important;
    }

    .wd-xl-500 {
        width: 500px;
    }

    .wd-xl-500p {
        width: 500%;
    }

    .mx-wd-xl-500p {
        max-width: 500%;
    }

    .mn-wd-xl-500p {
        min-width: 500%;
    }

    .wd-xl-500-force {
        width: 500px !important;
    }

    .wd-xl-500p-force {
        width: 500% !important;
    }

    .mx-wd-xl-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-xl-500p-force {
        min-width: 500% !important;
    }

    .wd-xl-550 {
        width: 550px;
    }

    .wd-xl-550p {
        width: 550%;
    }

    .mx-wd-xl-550p {
        max-width: 550%;
    }

    .mn-wd-xl-550p {
        min-width: 550%;
    }

    .wd-xl-550-force {
        width: 550px !important;
    }

    .wd-xl-550p-force {
        width: 550% !important;
    }

    .mx-wd-xl-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-xl-550p-force {
        min-width: 550% !important;
    }

    .wd-xl-600 {
        width: 600px;
    }

    .wd-xl-600p {
        width: 600%;
    }

    .mx-wd-xl-600p {
        max-width: 600%;
    }

    .mn-wd-xl-600p {
        min-width: 600%;
    }

    .wd-xl-600-force {
        width: 600px !important;
    }

    .wd-xl-600p-force {
        width: 600% !important;
    }

    .mx-wd-xl-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-xl-600p-force {
        min-width: 600% !important;
    }

    .wd-xl-650 {
        width: 650px;
    }

    .wd-xl-650p {
        width: 650%;
    }

    .mx-wd-xl-650p {
        max-width: 650%;
    }

    .mn-wd-xl-650p {
        min-width: 650%;
    }

    .wd-xl-650-force {
        width: 650px !important;
    }

    .wd-xl-650p-force {
        width: 650% !important;
    }

    .mx-wd-xl-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-xl-650p-force {
        min-width: 650% !important;
    }

    .wd-xl-700 {
        width: 700px;
    }

    .wd-xl-700p {
        width: 700%;
    }

    .mx-wd-xl-700p {
        max-width: 700%;
    }

    .mn-wd-xl-700p {
        min-width: 700%;
    }

    .wd-xl-700-force {
        width: 700px !important;
    }

    .wd-xl-700p-force {
        width: 700% !important;
    }

    .mx-wd-xl-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-xl-700p-force {
        min-width: 700% !important;
    }

    .wd-xl-750 {
        width: 750px;
    }

    .wd-xl-750p {
        width: 750%;
    }

    .mx-wd-xl-750p {
        max-width: 750%;
    }

    .mn-wd-xl-750p {
        min-width: 750%;
    }

    .wd-xl-750-force {
        width: 750px !important;
    }

    .wd-xl-750p-force {
        width: 750% !important;
    }

    .mx-wd-xl-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-xl-750p-force {
        min-width: 750% !important;
    }

    .wd-xl-800 {
        width: 800px;
    }

    .wd-xl-800p {
        width: 800%;
    }

    .mx-wd-xl-800p {
        max-width: 800%;
    }

    .mn-wd-xl-800p {
        min-width: 800%;
    }

    .wd-xl-800-force {
        width: 800px !important;
    }

    .wd-xl-800p-force {
        width: 800% !important;
    }

    .mx-wd-xl-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-xl-800p-force {
        min-width: 800% !important;
    }

    .wd-xl-850 {
        width: 850px;
    }

    .wd-xl-850p {
        width: 850%;
    }

    .mx-wd-xl-850p {
        max-width: 850%;
    }

    .mn-wd-xl-850p {
        min-width: 850%;
    }

    .wd-xl-850-force {
        width: 850px !important;
    }

    .wd-xl-850p-force {
        width: 850% !important;
    }

    .mx-wd-xl-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-xl-850p-force {
        min-width: 850% !important;
    }

    .wd-xl-900 {
        width: 900px;
    }

    .wd-xl-900p {
        width: 900%;
    }

    .mx-wd-xl-900p {
        max-width: 900%;
    }

    .mn-wd-xl-900p {
        min-width: 900%;
    }

    .wd-xl-900-force {
        width: 900px !important;
    }

    .wd-xl-900p-force {
        width: 900% !important;
    }

    .mx-wd-xl-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-xl-900p-force {
        min-width: 900% !important;
    }

    .wd-xl-950 {
        width: 950px;
    }

    .wd-xl-950p {
        width: 950%;
    }

    .mx-wd-xl-950p {
        max-width: 950%;
    }

    .mn-wd-xl-950p {
        min-width: 950%;
    }

    .wd-xl-950-force {
        width: 950px !important;
    }

    .wd-xl-950p-force {
        width: 950% !important;
    }

    .mx-wd-xl-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-xl-950p-force {
        min-width: 950% !important;
    }

    .wd-xl-1000 {
        width: 1000px;
    }

    .wd-xl-1000p {
        width: 1000%;
    }

    .mx-wd-xl-1000p {
        max-width: 1000%;
    }

    .mn-wd-xl-1000p {
        min-width: 1000%;
    }

    .wd-xl-1000-force {
        width: 1000px !important;
    }

    .wd-xl-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-xl-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-xl-1000p-force {
        min-width: 1000% !important;
    }

    .wd-xl-auto {
        width: auto;
    }

    .wd-xl-auto {
        width: auto !important;
    }
}
.wd-100v {
    width: 100vw;
}

.wd-1 {
    width: 1px;
}

.wd-2 {
    width: 2px;
}

.wd-3 {
    width: 3px;
}

.wd-4 {
    width: 4px;
}

.wd-6 {
    width: 6px;
}

.wd-7 {
    width: 7px;
}

.wd-8 {
    width: 8px;
}

.wd-9 {
    width: 9px;
}

.wd-16 {
    width: 16px;
}

.wd-32 {
    width: 32px;
}

.wd-36 {
    width: 36px;
}

.wd-48 {
    width: 48px;
}

.wd-64 {
    width: 64px;
}

.wd-72 {
    width: 72px;
}

.wd-170 {
    width: 170px;
}

.wd-180 {
    width: 180px;
}

.wd-190 {
    width: 190px;
}

.wd-220 {
    width: 220px;
}

.wd-230 {
    width: 230px;
}

.mx-wd-1350 {
    max-width: 1350px;
}
